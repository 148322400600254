import React, { useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  PDFDownloadLink,
  BlobProvider
} from '@react-pdf/renderer';

import logo from '../../../assets/images/RentometerLogo.png';
import save from '../../../assets/images/gpr/result/save.svg';
import regular from '../../../assets/fonts/Roboto/Roboto-Regular.ttf';
import medium from '../../../assets/fonts/Roboto/Roboto-Medium.ttf';
import bold from '../../../assets/fonts/Roboto/Roboto-Bold.ttf';

import { ReactComponent as PrintSvg } from '../../../assets/images/gpr/result/print.svg';
import { ReactComponent as DownloadSvg } from '../../../assets/images/gpr/result/save.svg';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: regular },
    { src: medium, fontWeight: 'medium' },
    { src: bold, fontWeight: 'bold' }
  ]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  container: {
    width: '100%',
    margin: 30,
    color: '#2D3035'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '40px'
  },
  headerLogo: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  headerLogoText: {
    fontWeight: 'normal',
    fontSize: '10px',
    color: '#717780',
    paddingLeft: '2px'
  },
  image: {
    display: 'flex',
    width: '85px',
    marginBottom: '6px'
  },
  headerDescription: {
    paddingBottom: '12px',
    width: '180px',
    borderBottom: '1px',
    borderBottomStyle: 'solid',
    borderColor: '#EF6F22'
  },
  headerName: {
    display: 'flex',
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: '16px',
    paddingBottom: '2px'
  },
  aside: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#F8FAFD',
    padding: '17px 16px',
    borderRadius: '8px',
    marginBottom: '30px'
  },
  asideItem: {
    width: '104px',
    height: '61px'
  },
  asideValue: {
    position: 'relative',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 'medium',
    marginBottom: '16px'
  },
  asideDescription: {
    fontSize: '10px',
    textAlign: 'center',
    fontWeight: 'normal',
    color: '#717780'
  },
  lables: {
    display: 'flex',
    flexDirection: 'column',
    color: '#2D3035',
    fontSize: '8px',
    fontWeight: 'medium',
    backgroundColor: '#F3F6F9'
  },
  labelItem: {
    width: '85px',
    padding: '8px 10px 8px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  table: {
    display: 'flex',
    flexDirection: 'row'
  },
  report: {
    display: 'flex',
    flexDirection: 'column',
    color: '#2D3035',
    fontSize: '8px'
  },
  reportGray: {
    display: 'flex',
    flexDirection: 'column',
    color: '#2D3035',
    fontSize: '8px',
    backgroundColor: '#F8FAFD'
  },
  reportItem: {
    textAlign: 'center',
    width: '95px',
    padding: '8px 10px 8px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchAdress: {
    color: '#2D3035',
    fontSize: '10px',
    marginBottom: '30px'
  },
  searchAdressText: {
    fontWeight: 'medium',
    marginLef: '10px'
  }
});

const NearbyDownloadPdf = ({ data, dataAfterFilter, diffRent }) => {
  const [isPrintActive, setIsPrintActive] = useState(false);

  const formatNumber = (number) => number?.toLocaleString().split(/\s/).join(',');
  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };
  const generateTimestamp = () => {
    let date = new Date();
    return `${
      (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
      '/' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '/' +
      date.getFullYear() +
      ', ' +
      formatAMPM(date)
    }`;
  };

  function getFormattedDate(currDate) {
    let date = new Date(currDate);
    let year = date.getFullYear().toString().slice(2, 4);
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  }

  const report = dataAfterFilter?.map((item, i) => {
    if (i <= 4) {
      return (
        <View style={(i + 1) % 2 !== 0 ? styles.report : styles.reportGray} key={i}>
          <Text style={styles.reportItem}>
            {item.address.length > 25 ? `${item.address.slice(0, 25)}...` : item.address}
          </Text>
          <Text style={styles.reportItem}>{item.property_type}</Text>
          <Text style={styles.reportItem}>{item.distance ? item.distance.toFixed(2) : '-'}</Text>
          <Text style={styles.reportItem}>{item.bedrooms}</Text>
          <Text style={styles.reportItem}>{item.baths}</Text>
          <Text style={styles.reportItem}>{item.price ? `$${formatNumber(item.price)}` : '-'}</Text>
          <Text style={styles.reportItem}>{item.sqft ? formatNumber(item.sqft) : '-'}</Text>
          <Text style={styles.reportItem}>
            {item.dollar_sqft ? `$${item.dollar_sqft.toFixed(2)}` : '-'}
          </Text>
          <Text style={styles.reportItem}>
            {item.last_seen ? getFormattedDate(item.last_seen) : '-'}
          </Text>
        </View>
      );
    }
  });

  const pdf = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerLogo}>
              <Image src={logo} style={styles.image} />
              <Text style={styles.headerLogoText}>{generateTimestamp()}</Text>
            </View>
            <View style={styles.headerDescription}>
              <Text style={styles.headerName}>Nearby Comps</Text>
            </View>
          </View>
          <Text style={styles.searchAdress}>
            Search address:{' '}
            <Text style={styles.searchAdressText}>{data.nearbyData.search_address}</Text>
          </Text>
          <View style={styles.aside}>
            <View style={styles.asideItem}>
              <Text style={styles.asideValue}>${data.summary.mean}</Text>
              <Text style={styles.asideDescription}>Average</Text>
            </View>
            <View style={styles.asideItem}>
              <Text style={styles.asideValue}>${data.summary.median}</Text>
              <Text style={styles.asideDescription}>Median</Text>
            </View>
            <View style={styles.asideItem}>
              <Text style={styles.asideValue}>${data.summary.percentile_25}</Text>
              <Text style={styles.asideDescription}>25th Percentile</Text>
            </View>
            <View style={styles.asideItem}>
              <Text style={styles.asideValue}>${data.summary.percentile_75}</Text>
              <Text style={styles.asideDescription}>75th Percentile</Text>
            </View>
            <View style={styles.asideItem}>
              <Text style={styles.asideValue}>{diffRent}%</Text>
              <Text style={styles.asideDescription}>Difference from the average</Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.lables}>
              <Text style={styles.labelItem}>Property {'\n'} Address</Text>
              <Text style={styles.labelItem}>Property Type</Text>
              <Text style={styles.labelItem}>Distance</Text>
              <Text style={styles.labelItem}>Bedrooms</Text>
              <Text style={styles.labelItem}>Baths</Text>
              <Text style={styles.labelItem}>Price</Text>
              <Text style={styles.labelItem}>Size (sqft)</Text>
              <Text style={styles.labelItem}>Price (sqft)</Text>
              <Text style={styles.labelItem}>List Date</Text>
            </View>
            {report}
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <PDFDownloadLink
        className="downloadNearbyPdf"
        document={pdf}
        fileName={`${data.nearbyData.search_address}.pdf`}
        style={{ textDecoration: 'none' }}>
        <DownloadSvg />
        <div className="downloadNearbyPdfText">Download PFD file</div>
      </PDFDownloadLink>

      <BlobProvider document={pdf}>
        {({ blob, url }) => (
          <a
            href={url ? url : '#'}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}>
            <div className="printNearbyPdf">
              <PrintSvg />
              <div className="downloadNearbyPdfText" onClick={() => setIsPrintActive(true)}>
                Print
              </div>
            </div>
          </a>
        )}
      </BlobProvider>
    </>
  );
};

export default NearbyDownloadPdf;
