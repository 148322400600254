import React from 'react';
import { Table } from 'antd';
import styles from './CompletedReportsTableRent.module.scss';
import dateFormat from 'dateformat';
import CompletedReportsDropdownRent from '../CompletedReportsDropdownRent/CompletedReportsDropdownRent.component';

export const CompletedReportsTableRent = ({
  rows,
  columns,
  handleDeleteReport,
  pagination,
  onChange
}) => {
  const customColumns = [
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      align: 'left',
      minWidth: 250,
      render: (item: string) => {
        return <div style={{ marginLeft: '18px' }}>{item}</div>;
      }
    },
    {
      title: 'Date Completed',
      key: 'createdAt',
      dataIndex: 'createdAt',
      align: 'left',
      width: 224,
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      sortDirections: ['ascend'],
      render: (item: string) => {
        return dateFormat(item, 'mmmm dS, yyyy');
      }
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: 70,
      render: (item: string) => {
        return (
          <div className={styles.actions}>
            <CompletedReportsDropdownRent report={item} handleDeleteReport={handleDeleteReport} />
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <Table
        locale={{
          emptyText: 'No Processed Reports'
        }}
        columns={columns || customColumns}
        dataSource={[...rows]}
        pagination={pagination}
        onChange={onChange}
        className={styles.completeTable}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};
