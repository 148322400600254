import {
  SET_API_KEY,
  GET_AUTH_INFO_REQUEST,
  GET_AUTH_INFO_SUCCESS,
  GET_AUTH_INFO_FAILURE
} from '../actions';
const initialState = {
  apiKey: '',
  keyIsLoaded: false,
  isLoading: false,
  authorized: false,
  account_email: '',
  credits_remaining: '',
  pro_report_credits_remaining: '',
  premium_credits_remaining: ''
};
export const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_API_KEY:
      return {
        ...state,
        apiKey: !payload ? state.apiKey : payload,
        keyIsLoaded: true
      };

    case GET_AUTH_INFO_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_AUTH_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authorized: payload.authorized,
        account_email: payload.account_email,
        credits_remaining: payload.credits_remaining,
        pro_report_credits_remaining: payload.pro_report_credits_remaining,
        premium_credits_remaining: payload.premium_credits_remaining
      };
    case GET_AUTH_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        authorized: false,
        account_email: '',
        credits_remaining: '',
        pro_report_credits_remaining: '',
        premium_credits_remaining: ''
      };
    default:
      return state;
  }
};
