import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import styles from './ReportTable.module.scss';
import { InputCell } from './CustomCells/InputCell.component';
import { SelectCell } from './CustomCells/SelectCell.component';
import { CELLS } from '../../../constants/constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as PlusSvg } from '../../../assets/images/gpr/plus.svg';
import Modal from 'react-modal';

export function ReportTable({
  rows,
  onAddRow,
  columns,
  batchAnalysisId,
  handleUpdateCell,
  isEditable,
  handleSelectedRows,
  selectedRowsForDelete
}) {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleAddRow = () => {
    onAddRow({ id: batchAnalysisId });
    const page = Math.ceil(rows.length / 10) + 1;
    if (page > 1) {
      if (currentPage !== page) {
        onPageChange(page);
      }
    }
  };

  const handleSelectRow = (value) => handleSelectedRows(value);

  const customColumns = [
    {
      title: (
        <div className={styles.tableAddressHeader}>
          Address
          <div className={styles.tableAddressDescription}>
            Manually enter property info if you do not have a CSV file to upload
          </div>
        </div>
      ),
      key: 'address',
      dataIndex: 'address',
      align: 'left',
      render: (text: string, record) => (
        <InputCell
          text={text}
          cell={CELLS.address}
          record={record}
          className={styles.inputCell}
          handleUpdateCell={handleUpdateCell}
        />
      ),
      className: styles.cell
    },
    {
      title: 'Beds',
      key: 'bedrooms',
      dataIndex: 'bedrooms',
      align: 'left',
      width: 186,
      render: (bedrooms: string, record) => {
        return (
          <SelectCell
            cell={CELLS.bedrooms}
            record={record}
            currentValue={bedrooms}
            handleUpdateCell={handleUpdateCell}
          />
        );
      },
      className: styles.cell
    },
    {
      title: 'Baths',
      key: 'baths',
      dataIndex: 'baths',
      align: 'left',
      width: 186,
      render: (baths: string, record) => {
        return (
          <SelectCell
            cell={CELLS.baths}
            record={record}
            currentValue={baths}
            handleUpdateCell={handleUpdateCell}
          />
        );
      },
      className: styles.cell
    }
  ];

  return (
    <div className={styles.container}>
      <Table
        locale={{
          emptyText: 'No Processed Batches'
        }}
        dataSource={[...rows]}
        columns={columns || customColumns}
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 10,
          showLessItems: true,
          current: currentPage,
          onChange: onPageChange
        }}
        className={styles.reportTable}
        rowSelection={
          isEditable
            ? {
                type: 'checkbox',
                ...selectedRowsForDelete,
                onChange: handleSelectRow
              }
            : undefined
        }
      />
      <button className={styles.addAnotherButton} onClick={handleAddRow}>
        <PlusSvg />
        <span className={styles.addAnotherAddressText}>
          Add {rows.length === 0 ? '' : 'another'} address
        </span>
      </button>
    </div>
  );
}
