import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import configureStore from './store/store';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './route/Routes';
import Header from './components/Header/Header';
import HelpCenter from './components/HelpCenter/HelpCenter.component';
import './index.scss';
import { CookiesProvider } from 'react-cookie';
const { store } = configureStore();

const App = () => (
  <CookiesProvider>
    <Provider store={store}>
      <Router>
        <Header />
        <Routes />
        <HelpCenter />
      </Router>
    </Provider>
  </CookiesProvider>
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
