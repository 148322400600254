import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import Select from 'react-select';
import Papa from 'papaparse';
import { useNavigate, useParams } from 'react-router-dom';
import { v1 as uuid } from 'uuid';
import Modal from 'react-modal';
import classNames from 'classnames';

import { Spin, Input, Radio } from 'antd';
import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons';

import styles from './Report.module.scss';
import ExampleCsv from '../../../assets/csv/Batch Processor Example_Input.csv';
import ExampleOutputCsv from '../../../assets/csv/Batch Processor Example_Output.csv';
import Background from '../../../assets/images/Background.png';
import { ReactComponent as ModalArrowSvg } from '../../../assets/images/ModalArrow.svg';
import { ReactComponent as ArrowToRight } from '../../../assets/images/arrow-right.svg';
import { ReactComponent as PlusSvg } from '../../../assets/images/gpr/plus.svg';
import { ReactComponent as CloseSvg } from '../../../assets/images/gpr/closeModal.svg';
import { ReactComponent as DeleteCloseSvg } from '../../../assets/images/deleteClose.svg';
import { ReactComponent as ViewReportsSvg } from '../../../assets/images/viewBatches.svg';
import { ReactComponent as RedoSvg } from '../../../assets/images/redo.svg';
import { ReactComponent as ProcessSvg } from '../../../assets/images/checkProcess.svg';
import { ReactComponent as ProcessNoDisableSvg } from '../../../assets/images/checkProcessNotDisable.svg';
import { ReactComponent as EdditSuffixSvg } from '../../../assets/images/editSuffix.svg';
import { ReactComponent as EnvelopeSvg } from '../../../assets/images/envelope.svg';

import {
  addNewAddress,
  deleteAddresses,
  updateTitle,
  updateAddressFields,
  buildReportRequest,
  updateAddressGlobalField,
  getAuthInfoRequest,
  createReport,
  formatAdressesBeforeBuild
} from '../../../store/actions';
import {
  customModalStyles,
  customSelectStyles,
  customStyles,
  customSelectAdditional,
  customDeleteStyles,
  FileHeader,
  ResultData,
  IFile
} from './Report.data';
import { IRootState } from '../../../constants/types';
import { SYSTEM_FIELD } from '../../../constants/constants';

import LeaveConfirmationModal from '../../../components/Modals/LeaveConfirmationModal/LeaveConfirmationModal.component';
import HorizontalLinearStepper from '../../../components/ProgressBar/ProgressBar.component';
import FAQComponent from '../../../components/FAQComponent/FAQComponent.component';
import { batchProcessorFAQ } from '../../../components/FAQComponent/FAQComponent.data';
import { ReportTable } from '../../../components/BatchProcessor/ReportTable/ReportTable.component';
import { DropZone } from '../../../components/BatchProcessor/Dropzone/Dropzone.component';
import {
  DropErrorMessage,
  LackOfAPIKeyMessage,
  LackOfCreditsMessage
} from '../../../components/Messages/MessageComponents.component';
import TitleBadge from '../../../components/TitleBadge/TitleBadge.component';

const Report = () => {
  const [isDropped, setIsDropped] = useState(false);
  const [filesLoading, setFilesLoading] = useState<{ fileId: string; loading: boolean }[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [handleRejectedDrop, setHendleRejectedDrop] = useState(false);
  const [dropRejectMsg, setDropRejectMsg] = useState('');
  const [lookBack, setLookBack] = useState(365);
  const [buildingType, setBuildingType] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filesHeader, setFilesHeader] = useState<{ value: string; label: string }[]>([]);
  const [firstFileHeader, setFirstFileHeader] = useState<FileHeader>({
    value: '',
    label: '',
    pressed: false
  });
  const [secondFileHeader, setSecondFileHeader] = useState<FileHeader>({
    value: '',
    label: '',
    pressed: false
  });
  const [thirdFileHeader, setThirdFileHeader] = useState<FileHeader>({
    value: '',
    label: '',
    pressed: false
  });
  const [firstPrefferedHeader, setFirstPrefferedHeader] = useState('Address');
  const [secondPrefferedHeader, setSecondPrefferedHeader] = useState('Bedrooms');
  const [thirdPrefferedHeader, setThirdPrefferedHeader] = useState('Baths');
  const [isOpenProcessModal, setIsOpenProcessModal] = useState(false);
  const [userCredits, setUserCredits] = useState(0);
  const [additionalHeaders, setAdditionalHeaders] = useState([]);
  const [mergeHeaders, setMergeHeaders] = useState([]);
  const [batchTitle, setBatchTitle] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [selectedRowsForDelete, setSelectedRowsForDelete] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [leaveModalIsOpen, setLeaveModalIsOpen] = useState(false);
  const [goBack, setGoBack] = useState(false);

  const [modalStep, setModalStep] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const batchAnalysisId = params.id;

  const { history } = useSelector((state: IRootState) => state.batchAnalysisReducer);
  const { addresses = [], title = '', status = '' } = params.id ? history[params.id] || {} : {};
  const user = useSelector((state: IRootState) => state.authReducer);

  const toLowerKeys = (obj) => {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = obj[key];
      return accumulator;
    }, {});
  };

  useEffect(() => {
    if (batchAnalysisId !== undefined) {
      if (!Object.prototype.hasOwnProperty.call(history, batchAnalysisId)) {
        navigate('/batch-processor/not-found');
      }
    }
  }, [batchAnalysisId]);

  useEffect(() => {
    if (!files.length && !filesLoading.length) {
      setIsDropped(false);
    }
  }, [files, filesLoading]);

  useEffect(() => {
    setUserCredits(user.credits_remaining);
    dispatch(getAuthInfoRequest({ authKey: user.apiKey }));
  }, [isOpenProcessModal]);

  const handleSelectedRows = (data) => setSelectedRowsForDelete(data);

  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const handleConfirmDeleteModal = () => {
    handleDeleteRow(selectedRowsForDelete);
    cancelEditable();
    closeDeleteModal();
    setSelectedRowsForDelete([]);
  };

  const resetHeaderState = () => {
    setFirstFileHeader({
      value: '',
      label: '',
      pressed: false
    });
    setSecondFileHeader({
      value: '',
      label: '',
      pressed: false
    });
    setThirdFileHeader({
      value: '',
      label: '',
      pressed: false
    });
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    resetHeaderState();
    handleNewBatchReportClick();
    setModalIsOpen(false);
    setModalStep(0);
    setGoBack(false);
  };
  const closeModalAtferConfirm = () => {
    resetHeaderState();
    setModalIsOpen(false);
    setModalStep(0);
    setGoBack(false);
    setMergeHeaders([]);
  };

  const isDisableHeadersModal = () => {
    return !(firstFileHeader.value && secondFileHeader.value && thirdFileHeader.value);
  };

  const handleConfirmModal = () => {
    addresses
      .filter((file) => file.fileId === files[files.length - 1]?.fileId)
      .map((item) => {
        if (firstFileHeader.label !== firstPrefferedHeader) {
          const addressId = item.id;
          const value = Object.keys(item).find(
            (header) => header === firstPrefferedHeader.toLowerCase()
          );
          let fields = {};
          if (value !== undefined) {
            fields[value] = item[firstFileHeader.value].replace(/#/g, '');
          }
          delete item[firstFileHeader.value];
          dispatch(updateAddressFields({ id: batchAnalysisId, addressId, fields, status }));
        }
        if (secondFileHeader.label !== secondPrefferedHeader) {
          const addressId = item.id;
          const value = Object.keys(item).find(
            (header) => header === secondPrefferedHeader.toLowerCase()
          );
          let fields = {};
          if (value !== undefined) {
            fields[value] = item[secondFileHeader.value];
          }
          delete item[secondFileHeader.value];
          dispatch(updateAddressFields({ id: batchAnalysisId, addressId, fields, status }));
        }
        if (thirdFileHeader.label !== thirdPrefferedHeader) {
          const addressId = item.id;
          const value = Object.keys(item).find(
            (header) => header === thirdPrefferedHeader.toLowerCase()
          );
          let fields = {};
          if (value !== undefined) {
            fields[value] = item[thirdFileHeader.value];
            if (fields[value] != '1' && fields[value] && fields[value] != '0') {
              fields[value] = '1.5';
            } else if (fields[value] == '0') {
              fields[value] = '';
            }
          }
          delete item[thirdFileHeader.value];
          dispatch(updateAddressFields({ id: batchAnalysisId, addressId, fields, status }));
        }
        if (additionalHeaders.length > 0) {
          const addressId = item.id;
          const fields = {
            additionalHeader: additionalHeaders.reduce((acc, { value }, i) => {
              if (value === 'id') {
                return {
                  ...acc,
                  [value]: item?.prevId
                };
              } else if (value && SYSTEM_FIELD.includes(value)) {
                return {
                  ...acc,
                  [value + '_input']: item[value]
                };
              } else {
                return {
                  ...acc,
                  [value]: item[value]
                };
              }
            }, {} as any)
          };
          dispatch(updateAddressFields({ id: batchAnalysisId, addressId, fields, status }));
        }
      });
    dispatch(formatAdressesBeforeBuild({ id: batchAnalysisId }));
    closeModalAtferConfirm();
  };
  const handleEditTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBatchTitle(event.target.value);
    dispatch(updateTitle({ id: batchAnalysisId, title: event.target.value }));
  };

  const handleDrop = (acceptedFiles: File[], rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const errorMessage = rejectedFiles.map((item) => {
        if (item.file.name && !item.file.name.endsWith('.csv')) {
          setDropRejectMsg('Inappropriate file type. Please load only CSV format.');
        } else if (item.file.size > 1024 * 1024) {
          setDropRejectMsg(
            'File is too large. Please attempt to load a file that is under 1 MB in size.'
          );
        } else
          setDropRejectMsg(
            `${item.file.name || 'Unknown file'} was rejected for an unknown reason.`
          );
      });
    }
    setHendleRejectedDrop(false);
    if (acceptedFiles.length === 0) {
      setHendleRejectedDrop(true);
      return;
    }
    setFilesHeader([]);
    setIsDropped(true);
    let emptyAddresses = addresses.map((item) => {
      if (item.address === '') {
        return item.id;
      }
    });
    dispatch(deleteAddresses({ id: batchAnalysisId, addresses: emptyAddresses }));
    acceptedFiles.map((file) => {
      const fileId = uuid();
      setFilesLoading((prev) => [...prev, { fileId: fileId, loading: true }]);
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const resultsData = results.data.map((item) => {
            return toLowerKeys(item);
          });
          const fileHeaders = Object.keys(resultsData[0]);
          fileHeaders.map((item) => {
            setFilesHeader((prev) => [
              ...prev,
              { value: item, label: item.charAt(0).toUpperCase() + item.slice(1) }
            ]);
          });
          fileHeaders.find((header) => {
            if (header === firstPrefferedHeader.toLocaleLowerCase()) {
              setFirstFileHeader({
                value: firstPrefferedHeader.toLocaleLowerCase(),
                label: firstPrefferedHeader,
                pressed: false
              });
            } else if (header === secondPrefferedHeader.toLocaleLowerCase()) {
              setSecondFileHeader({
                value: secondPrefferedHeader.toLocaleLowerCase(),
                label: secondPrefferedHeader,
                pressed: false
              });
            } else if (header === thirdPrefferedHeader.toLocaleLowerCase()) {
              setThirdFileHeader({
                value: thirdPrefferedHeader.toLocaleLowerCase(),
                label: thirdPrefferedHeader,
                pressed: false
              });
            } else {
              openModal();
            }
          });
          resultsData.forEach((item: ResultData) => {
            if (item?.baths != '1' && item?.baths && item?.baths != '0') {
              item.baths = '1.5';
            } else if (item?.baths == '0') {
              item.baths = '';
            }

            item.address = item?.address?.replace(/#/g, '');
            item.prevId = item?.id;
            item.building_type = item.building_type ? item.building_type : buildingType;
            item.look_back_days = lookBack;
            const randomValue = uuid();
            item.id = randomValue;
            item.key = randomValue;
            item.fileName = file.name;
            item.fileId = fileId;
            dispatch(addNewAddress({ id: batchAnalysisId, address: item }));
          });
          setFilesLoading((prev) => {
            prev.map((item) => {
              if (item.fileId === fileId) {
                item.loading = false;
              }
              return item;
            });
            return [...prev];
          });
        }
      });
      setFiles((prev) => [...prev, { file: file, name: file.name, fileId: fileId }]);
    });
  };

  const handleRemoveFile = (removedFile) => {
    const newFiles = files.filter((file) => file.fileId !== removedFile.fileId);
    const newFilesLoading = filesLoading.filter((file) => file.fileId !== removedFile.fileId);
    setFiles(newFiles);
    setFilesLoading(newFilesLoading);
    let addressesToDelete = addresses.map((item) => {
      if (item.fileId === removedFile.fileId) return item.id;
    });
    const newId = uuid();
    dispatch(
      deleteAddresses({ id: batchAnalysisId, addresses: addressesToDelete, newId, newKey: newId })
    );
  };

  const handleUpdateCell = ({ addressId, fields }) =>
    dispatch(updateAddressFields({ id: batchAnalysisId, addressId, fields, status }));

  const handleAddRow = ({ id }) => dispatch(addNewAddress({ id, status }));

  const handleDeleteRow = (data) => {
    const newId = uuid();
    dispatch(
      deleteAddresses({ id: batchAnalysisId, addresses: data, status, newId, newKey: newId })
    );
  };

  const handleLookBackChange = (event) => {
    setLookBack(event.target.value);
    dispatch(
      updateAddressGlobalField({
        id: batchAnalysisId,
        field: 'look_back_days',
        value: event.target.value
      })
    );
  };
  const handleBuildingTypeChange = (event) => {
    setBuildingType(event.target.value);
    dispatch(
      updateAddressGlobalField({
        id: batchAnalysisId,
        field: 'building_type',
        value: event.target.value
      })
    );
  };

  const handleConfirmMerge = () => {
    addresses
      .filter((file) => file.fileId === files[files.length - 1]?.fileId)
      .map((item) => {
        if (mergeHeaders.length > 0) {
          const addressId = item.id;
          const fields = {
            merged_address: mergeHeaders
              .reduce((acc, { value }) => {
                return [...acc, item[value]];
              }, [] as string[])
              .join(', ')
          };
          dispatch(updateAddressFields({ id: batchAnalysisId, addressId, fields, status }));
          setFilesHeader((prev) => [
            {
              value: 'merged_address',
              label: 'Merged Address'
            },
            ...prev
          ]);
        }
      });
    setModalStep(1);
    setGoBack(true);
  };

  const { getInputProps, open } = useDropzone({
    accept: { 'text/csv': ['.csv'] },
    onDrop: handleDrop,
    minSize: 0,
    maxSize: 1048576
  });

  const openProcessModal = () => {
    if (!batchTitle) {
      setBatchTitle('[name your file]');
      dispatch(updateTitle({ id: batchAnalysisId, title: '[name your file]' }));
    }
    setIsOpenProcessModal(true);
  };
  const closeProcessModal = () => setIsOpenProcessModal(false);
  const handleSkipStep = (step) => {
    setModalStep(step);
  };
  const handleGoBack = (step) => {
    setModalStep(step);
  };

  const openLeaveModal = () => {
    if (isDropped || addresses.length > 1) {
      setLeaveModalIsOpen(true);
    } else {
      handleCompletedReportsClick();
    }
  };
  const closeLeaveModal = () => setLeaveModalIsOpen(false);

  const handleidEditable = () => {
    setIsEditable((state) => !state);
    setSelectedRowsForDelete([]);
  };
  const cancelEditable = () => {
    setIsEditable(false);
    setSelectedRowsForDelete([]);
  };

  const handleBuildReport = () => {
    let id = batchAnalysisId;
    if (id === 'new') {
      id = uuid();
    }

    if (buildingType) {
      dispatch(
        updateAddressGlobalField({
          id: batchAnalysisId,
          field: 'building_type',
          value: buildingType
        })
      );
    }

    const historyValues = Object.values(history);
    const matchingItems = historyValues.filter(
      (item) => item && item.title && item.title.replace(/\s*\((\d+)\)\s*$/, '') === batchTitle
    );
    if (matchingItems.length > 1) {
      const itemCount = matchingItems.length;
      const updatedTitle = `${batchTitle} (${itemCount})`;

      dispatch(updateTitle({ id: batchAnalysisId, title: updatedTitle }));
    }

    dispatch(
      buildReportRequest({
        authKey: user.apiKey,
        batchAnalysisId: id,
        addresses: addresses
      })
    );
    navigate(`/batch-processor/${id}/reports`, { state: { id: id } });
  };

  const handleNewBatchReportClick = () => {
    setFiles([]);
    setHendleRejectedDrop(false);
    setDropRejectMsg('');
    setFilesLoading([]);
    setLookBack(365);
    setBuildingType('');
    setIsDropped(false);
    setAdditionalHeaders([]);
    setMergeHeaders([]);
    dispatch(createReport({ id: 'new', title: '' }));
    navigate(`/batch-processor/new`);
  };

  const handleCompletedReportsClick = () => navigate('/batch-processor');

  const dropError = handleRejectedDrop ? (
    <div className={styles.erroDropzone}>
      <span className={styles.creditsAvailabilityText}>{dropRejectMsg}</span>
    </div>
  ) : null;

  return (
    <>
      <div
        className={styles.container}
        style={{
          backgroundImage: `url(${Background}), linear-gradient(180deg, #DFE8F2 7.67%, #F4F5F7 100%)`
        }}>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customModalStyles}
          shouldCloseOnOverlayClick={false}>
          <CloseSvg className={styles.closeModalIcon} onClick={() => closeModal()} />
          <div style={{ alignSelf: 'center', width: '150px', marginBottom: '30px' }}>
            <HorizontalLinearStepper page={modalStep} />
          </div>
          {modalStep === 0 ? (
            <div className={styles.modalContainer}>
              <div className={styles.modalTitle}>Merge Address Fields (optional)</div>
              <span className={styles.modalDescriptionFirst}>
                The Batch Processor requires the entire address to be in a single field (e.g.
                Street, City, State, Zip).
                <ul>
                  <li>If the address in your input file is in a single field, skip this step</li>
                  <li>
                    If your address is in multiple fields, complete the step below to merge it to a
                    single field
                  </li>
                </ul>
              </span>
              <div className={styles.additionalItem}>
                <div className={styles.modalFirstLabel}>Choose fields to merge</div>
                <Select
                  isMulti
                  placeholder="e.g. Street, City, State, Zip"
                  options={[...new Map(filesHeader.map((item) => [item['value'], item])).values()]}
                  onChange={(option: any) => {
                    setMergeHeaders(option);
                  }}
                  isOptionDisabled={() => mergeHeaders.length > 3}
                  styles={customSelectAdditional}
                  className={styles.additionalInput}
                />
              </div>
              <button
                onClick={handleConfirmMerge}
                className={styles.modalButtonFirst}
                disabled={mergeHeaders.length === 0}>
                Next
              </button>
              <a onClick={() => handleSkipStep(1)} className={styles.modalButtonSkip}>
                Skip this step <ArrowToRight />
              </a>
            </div>
          ) : modalStep === 1 ? (
            <div className={styles.modalContainer}>
              <div className={styles.modalTitle}>Match Field Names</div>
              <span className={styles.modalDescriptionFirst}>
                The column headers in your input file need to match our required fields
                <ul>
                  <li>If the required headers match, you can skip this step</li>
                  <li>If needed, match the required field where indicated.</li>
                </ul>
              </span>
              <div className={styles.headersContainer}>
                <div className={styles.prefferedHeadersContainer}>
                  <Input disabled value="Required Fields" className={styles.headerInput} />
                  <Input disabled value={firstPrefferedHeader} className={styles.disabledInput} />
                  <Input disabled value={secondPrefferedHeader} className={styles.disabledInput} />
                  <Input disabled value={thirdPrefferedHeader} className={styles.disabledInput} />
                </div>
                <div className={styles.modalArrowContainer}>
                  <ModalArrowSvg className={styles.modalArrow} />
                  <ModalArrowSvg className={styles.modalArrow} />
                  <ModalArrowSvg className={styles.modalArrow} />
                  <ModalArrowSvg className={styles.modalArrow} />
                </div>
                <div className={styles.fileHeadersContainer}>
                  <Input disabled value="Input File Column Header" className={styles.headerInput} />
                  <Select
                    required
                    placeholder="Select matching field"
                    value={
                      firstFileHeader.pressed ||
                      (firstFileHeader.label === firstPrefferedHeader && mergeHeaders.length === 0)
                        ? firstFileHeader
                        : null
                    }
                    onChange={(value) => {
                      setFirstFileHeader({
                        value: value ? value.value : '',
                        label: value
                          ? value.value.charAt(0).toUpperCase() + value?.value.slice(1)
                          : '',
                        pressed: true
                      });
                    }}
                    options={[
                      ...new Map(filesHeader.map((item) => [item['value'], item])).values()
                    ]}
                    styles={customSelectStyles}
                    className={classNames(
                      styles.headerSelect,
                      firstFileHeader.label !== firstPrefferedHeader &&
                        !firstFileHeader.pressed &&
                        styles.unmatchedHeaderSelect
                    )}
                  />
                  <Select
                    required
                    placeholder="Select matching field"
                    value={
                      secondFileHeader.pressed || secondFileHeader.label === secondPrefferedHeader
                        ? secondFileHeader
                        : null
                    }
                    onChange={(value) => {
                      setSecondFileHeader({
                        value: value ? value.value : '',
                        label: value
                          ? value.value.charAt(0).toUpperCase() + value?.value.slice(1)
                          : '',
                        pressed: true
                      });
                    }}
                    options={[
                      ...new Map(filesHeader.map((item) => [item['value'], item])).values()
                    ]}
                    styles={customSelectStyles}
                    className={classNames(
                      styles.headerSelect,
                      secondFileHeader.label !== secondPrefferedHeader &&
                        !secondFileHeader.pressed &&
                        styles.unmatchedHeaderSelect
                    )}
                  />
                  <Select
                    required
                    placeholder="Select matching field"
                    value={
                      thirdFileHeader.pressed || thirdFileHeader.label === thirdPrefferedHeader
                        ? thirdFileHeader
                        : null
                    }
                    onChange={(value) => {
                      setThirdFileHeader({
                        value: value ? value.value : '',
                        label: value
                          ? value.value.charAt(0).toUpperCase() + value?.value.slice(1)
                          : '',
                        pressed: true
                      });
                    }}
                    options={[
                      ...new Map(filesHeader.map((item) => [item['value'], item])).values()
                    ]}
                    styles={customSelectStyles}
                    className={classNames(
                      styles.headerSelect,
                      thirdFileHeader.label !== thirdPrefferedHeader &&
                        !thirdFileHeader.pressed &&
                        styles.unmatchedHeaderSelect
                    )}
                  />
                </div>
              </div>
              <div className={styles.btnContainer}>
                <button
                  onClick={() => handleGoBack(0)}
                  className={classNames(styles.confirmModalButton, styles.backBtn)}
                  disabled={goBack}>
                  Back
                </button>
                <button
                  onClick={() => handleSkipStep(2)}
                  className={styles.confirmModalButton}
                  disabled={isDisableHeadersModal()}>
                  Next
                </button>
              </div>
            </div>
          ) : (
            <div className={styles.modalContainer}>
              <div className={styles.modalTitle}>Additional Columns (optional)</div>
              <span className={styles.modalDescriptionFirst}>
                You can select up to 5 additional fields from your input file that will be included
                in the returned Batch file (For example: ID, size/sf, current rent, year built,
                etc.).
              </span>
              <div className={styles.additionalItem}>
                <Select
                  isMulti
                  placeholder="(Optional) Please, select additional column..."
                  options={[...new Map(filesHeader.map((item) => [item['value'], item])).values()]}
                  onChange={(option: any) => {
                    setAdditionalHeaders(option);
                  }}
                  isOptionDisabled={() => additionalHeaders.length > 4}
                  styles={customSelectAdditional}
                  className={styles.additionalInput}
                />
              </div>
              <button onClick={handleConfirmModal} className={styles.modalButtonFirst}>
                Confirm
              </button>
            </div>
          )}
        </Modal>
        <div className={styles.contentContainer}>
          <div className={styles.contentHeader}>
            <div className={styles.contentHeaderContainer}>
              <h2 className={styles.contentHeaderTitle}>
                Rentometer Batch Processor
                <TitleBadge text={'BETA'} />
              </h2>
              <div className={styles.contentHeaderSubTitle}>
                Receive rent prices for up to 500 properties
              </div>
            </div>
            <div className={styles.contentHeaderBtnContainer}>
              <a
                className={styles.contentHeaderBtnFeedback}
                href="https://www.rentometer.com/feedback/new"
                rel="noreferrer"
                target="_blank">
                <EnvelopeSvg />
                Send Feedback
              </a>
              <button className={styles.contentHeaderBtn} onClick={openLeaveModal}>
                <ViewReportsSvg />
                View History
              </button>
            </div>

            <LeaveConfirmationModal
              isOpen={leaveModalIsOpen}
              onRequestClose={closeLeaveModal}
              onConfirm={handleCompletedReportsClick}
            />
          </div>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.titleContainer}>
                <div className={styles.batchTitleContainer}>
                  <Input
                    placeholder="[name your file]"
                    className={styles.input}
                    value={title}
                    onChange={handleEditTitle}
                    suffix={
                      <EdditSuffixSvg
                        style={{
                          color: '#5B6878',
                          cursor: 'pointer'
                        }}
                      />
                    }
                    required
                  />
                  <div className={styles.batchTitleDescription}>
                    Name your output file (e.g. Tampa 3 Bedroom Homes)
                  </div>
                </div>
                <div className={styles.buildReportButtonContainer}>
                  <button
                    className={styles.resetReportButton}
                    onClick={() => handleNewBatchReportClick()}>
                    <RedoSvg />
                    Reset
                  </button>
                  <div className={styles.buildReport}>
                    <div className={styles.buildReportHint}>
                      <button
                        className={styles.buildReportButton}
                        onClick={() => openProcessModal()}
                        disabled={
                          addresses.length <= 0 ||
                          user.credits_remaining < addresses.length ||
                          addresses.find((item) => item.address === '') ||
                          !buildingType
                        }>
                        {addresses.length <= 0 ||
                        user.credits_remaining < addresses.length ||
                        addresses.find((item) => item.address === '') ||
                        !buildingType ? (
                          <ProcessSvg />
                        ) : (
                          <ProcessNoDisableSvg />
                        )}
                        Process
                      </button>
                      <span className={styles.hide}>
                        {user.authorized
                          ? addresses.length <= 0 ||
                            user.credits_remaining < addresses.length ||
                            (addresses.find((item) => item.address === '') && !buildingType)
                            ? 'Please fill or delete empty addresses and select a building type'
                            : buildingType
                            ? 'Please fill empty addresses or delete them'
                            : 'Please select a building type to run the report'
                          : 'Please set the API key to start the report'}
                        <div className={styles.hideTriangle}></div>
                      </span>
                    </div>
                    <div className={styles.creditBalanceContainer}>
                      <div className={styles.creditBalance}>
                        QuickView credit balance: {user.credits_remaining}
                      </div>
                      <div>
                        <a
                          className={styles.creditBalanceBuy}
                          href="https://www.rentometer.com/accounts/edit"
                          target="_blank"
                          rel="noopener noreferrer">
                          Purchase more credits
                        </a>
                      </div>
                    </div>
                  </div>

                  <Modal
                    isOpen={isOpenProcessModal}
                    onRequestClose={closeProcessModal}
                    style={customStyles}>
                    <CloseSvg
                      className={styles.closeModalIcon}
                      onClick={() => closeProcessModal()}
                    />
                    <h1 className={styles.processModalTitle}>Start process</h1>
                    <span className={styles.processModalDescription}>
                      Your QuickView credit balance: {user.credits_remaining}
                    </span>
                    <span className={styles.processModalDescription}>
                      Process cost: {addresses.length}{' '}
                      {addresses.length === 1 ? 'QuickView credit' : 'QuickView credits'}
                    </span>
                    <span className={styles.processModalDescriptionText}>
                      Chosen filters will affect all the addresses.
                      <br />
                      You will not be charged for null results.
                    </span>
                    <div className={styles.modalButtonContainer}>
                      <button
                        onClick={() => closeProcessModal()}
                        className={styles.closeProcessModalButton}>
                        Cancel
                      </button>
                      <button
                        onClick={() => handleBuildReport()}
                        className={styles.confirmProcessModalButton}>
                        Confirm
                      </button>
                    </div>
                  </Modal>
                </div>
              </div>
              {!isDropped ? (
                <div className={styles.dropzoneContainer}>
                  <DropZone onDrop={handleDrop} minSize={0} maxSize={1048576} />
                  <DropErrorMessage shouldDisplay={handleRejectedDrop} content={dropRejectMsg} />
                  <div className={styles.infoContainer}>
                    <span className={styles.infoDescription}>
                      Upload a CSV of up to 500 properties
                    </span>
                    <span className={styles.infoDescription}>
                      <a
                        href={ExampleCsv}
                        download={'Batch Processor Example_Input'}
                        className={styles.infoDownload}>
                        Download a sample of input
                      </a>{' '}
                      to see what your CSV should look like
                    </span>
                    <span className={styles.infoDescription}>
                      <a
                        href={ExampleOutputCsv}
                        download="Batch Processor Example_Output"
                        className={styles.infoDownload}>
                        Download a sample of output
                      </a>{' '}
                      to see an example of results
                    </span>
                    <span
                      className={classNames(
                        styles.infoDescriptionWithHint,
                        styles.infoDescription
                      )}>
                      Formatting questions?{' '}
                      <div className={styles.infoContainerHint}>
                        <InfoCircleOutlined className={styles.infoIcon1} />
                        <span className={styles.hideIcon1}>
                          Please note:
                          <ul>
                            <li>The “#” sign is not allowed</li>
                            <li>The entire address must be in one field</li>
                            <li>Zip and Country are not required</li>
                          </ul>
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              ) : (
                <>
                  <div className={styles.uploadedFileContainer}>
                    <div className={styles.uploadedFiles}>
                      {files.map((item, index) => (
                        <div key={index} className={styles.fileContainer}>
                          <span className={styles.fileName}>{item.file.name.slice(0, 29)}...</span>
                          {filesLoading.filter((fileLoad) => fileLoad.fileId === item.fileId)[0]
                            .loading ? (
                            <Spin
                              indicator={<ReloadOutlined className={styles.reloadFileIcon} spin />}
                            />
                          ) : (
                            <CloseSvg
                              className={styles.removeFileIcon}
                              onClick={() => handleRemoveFile(item)}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                    <div style={{ flex: '0 0 auto' }}>
                      <input {...getInputProps()} />
                      <div className={styles.addAnotherButton} onClick={open}>
                        <PlusSvg />
                        <span className={styles.uploadAnotherButton}>Add another file</span>
                      </div>
                    </div>
                  </div>
                  <DropErrorMessage shouldDisplay={handleRejectedDrop} content={dropRejectMsg} />
                </>
              )}
            </div>
            <div className={styles.tableContainer}>
              {addresses.length > 0 && (
                <div className={styles.globalFiltersContainer}>
                  <div className={styles.globalFiltersContent}>
                    <div className={styles.filtersTitleContainer}>
                      <h4 className={styles.filtersTitle}>Choose filters</h4>
                      <InfoCircleOutlined className={styles.infoIcon} />
                      <span className={styles.hideIcon}>
                        Please note the filters will apply to all addresses
                      </span>
                    </div>
                    <div className={styles.lookBackContainer}>
                      <span className={styles.lookBackText}>Look back period:</span>
                      <Radio.Group
                        onChange={handleLookBackChange}
                        value={lookBack}
                        defaultValue={lookBack}>
                        <Radio value={365}>1 year</Radio>
                        <Radio value={180}>6 months</Radio>
                        <Radio value={90}>3 months</Radio>
                      </Radio.Group>
                    </div>
                    <div className={styles.buildingTypeContainer}>
                      <span className={styles.buildingTypeText}>Building type (required): </span>
                      <Radio.Group onChange={handleBuildingTypeChange} value={buildingType}>
                        <Radio value="house">House/Duplex</Radio>
                        <Radio value="apartment">Apt/Condo</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className={styles.globalFiltersEdit}>
                    {selectedRowsForDelete.length > 0 && (
                      <div className={styles.clearButtonContainer}>
                        <button
                          onClick={() => openDeleteModal()}
                          className={styles.clearSelectionButton}>
                          <DeleteCloseSvg width="11px" height="11px" />
                          <span className={styles.clearSelectionText}>Delete Selected</span>
                        </button>
                        <Modal
                          isOpen={isDeleteModalOpen}
                          onRequestClose={closeDeleteModal}
                          style={customDeleteStyles}
                          shouldCloseOnOverlayClick={false}>
                          <CloseSvg
                            className={styles.closeDeleteModalIcon}
                            onClick={() => closeDeleteModal()}
                          />
                          <h1 className={styles.deleteModalTitle}>Delete Selected?</h1>
                          <span className={styles.deleteModalDescription}>
                            Are you sure you want to delete all selected items?
                          </span>
                          <div className={styles.deleteModalButtonContainer}>
                            <button
                              onClick={() => closeDeleteModal()}
                              className={styles.closeDeleteModalButton}>
                              Cancel
                            </button>
                            <button
                              onClick={() => handleConfirmDeleteModal()}
                              className={styles.confirmDeleteModalButton}>
                              Delete
                            </button>
                          </div>
                        </Modal>
                      </div>
                    )}
                    <button
                      className={classNames(
                        isEditable ? styles.globalFiltersEditCancel : styles.globalFiltersEditList
                      )}
                      onClick={handleidEditable}>
                      {isEditable ? 'Cancel' : 'Edit List'}
                    </button>
                  </div>
                </div>
              )}
              <LackOfCreditsMessage shouldDisplay={addresses.length > user.credits_remaining} />
              <LackOfAPIKeyMessage authorized={user.authorized} />
              <ReportTable
                columns={null}
                rows={modalIsOpen ? [] : addresses}
                onAddRow={handleAddRow}
                batchAnalysisId={batchAnalysisId}
                handleUpdateCell={handleUpdateCell}
                isEditable={isEditable}
                handleSelectedRows={handleSelectedRows}
                selectedRowsForDelete={selectedRowsForDelete}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(styles.container, styles.grayContainer)}>
        <div className={styles.contentContainer}>
          <FAQComponent data={batchProcessorFAQ} />
        </div>
      </div>
    </>
  );
};

export default connect()(Report);
