import React from 'react';
import styles from './MessageComponents.module.scss';

interface DropErrorMessageProps {
  content: string;
  shouldDisplay: boolean;
}

const DropErrorMessage: React.FC<DropErrorMessageProps> = ({ shouldDisplay, content }) => {
  if (!shouldDisplay) {
    return null;
  }

  return (
    <div className={styles.errorDropzone}>
      <span className={styles.creditsAvailabilityText}>{content}</span>
    </div>
  );
};

export default DropErrorMessage;
