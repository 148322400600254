import React, { useRef, useEffect } from 'react';
import styles from './CustomGoogleAutocomplete.module.scss';
import { RedoOutlined } from '@ant-design/icons';

interface CustomGoogleAutocompleteProps {
  handleResetReport: () => void;
  handleAddressChange: (e) => void;
  defaultAddress: string;
}

const CustomGoogleAutocomplete: React.FC<CustomGoogleAutocompleteProps> = ({
  handleResetReport,
  handleAddressChange,
  defaultAddress
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const options = {
    componentRestrictions: { country: 'us' },
    types: ['geocode']
  };

  useEffect(() => {
    if (window.google && window.google.maps) {
      const autoComplete = new window.google.maps.places.Autocomplete(
        inputRef.current as HTMLInputElement,
        options
      );

      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace();
        if (!place?.geometry?.location) {
          alert('This location is not available');
        } else {
          handleAddressChange(place.formatted_address);
        }
      });

      if (inputRef.current) {
        inputRef.current.value = defaultAddress;
      }
    }
  }, [defaultAddress]);

  const handleReset = () => {
    handleResetReport();
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <>
      <div className={styles.item__Label}>Subject Address or Point of Interest</div>
      <div className={styles.autocompleteContainer}>
        <input
          ref={inputRef}
          className={styles.autocompleteInput}
          placeholder="Enter an address or point of interest"
        />
        <div className={styles.resetButton} onClick={handleReset}>
          Reset <RedoOutlined />
        </div>
      </div>
    </>
  );
};

export default CustomGoogleAutocomplete;
