import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { updateInputField, updateMultiAddress } from '../../../../store/actions';
import { IRootState } from '../../../../constants/types';

interface CustomAutocompleteProps {
  id?: number;
}

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({ id }) => {
  const dispatch = useDispatch();

  const { activeReportType } = useSelector((state: IRootState) => state.gprCalculatorReducer);
  const { activeSingleReport } = useSelector((state: IRootState) => state.gprCalculatorReducer);
  const { activeMultiReport } = useSelector((state: IRootState) => state.gprCalculatorReducer);

  const { id: singleReportId, data: singleReportData } = activeSingleReport;
  const {
    id: multiReportId,
    data: multiReportData,
    address: multipleReportAddress
  } = activeMultiReport;

  const onUpdateField = (e) => {
    if (activeReportType === 'single') {
      dispatch(updateInputField({ id, type: 'address', activeReportType: 'single', value: e }));
    } else {
      dispatch(updateMultiAddress(e));
    }
  };

  return (
    <GooglePlacesAutocomplete
      apiKey="AIzaSyAwopNugn6tNb-1hXDY_ORinP5wV16vTUo"
      apiOptions={{ language: 'en', region: 'us' }}
      autocompletionRequest={{
        types: ['address'],
        componentRestrictions: {
          country: 'us'
        }
      }}
      selectProps={{
        isClearable: true,
        placeholder: '',
        value:
          activeReportType === 'single'
            ? singleReportData.filter((item, number) => number === id)[0].address
            : multipleReportAddress,
        onChange: (e) => onUpdateField(e),
        components: {
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          IndicatorsContainer: () => null
        },
        styles: {
          noOptionsMessage: () => ({
            margin: 0
          }),
          loadingMessage: () => ({
            margin: 0
          }),
          control: (base) => ({
            ...base,
            heightminWidth: '50px',
            border: '1px solid #b3bcc9',
            borderRadius: '4px',
            textAlign: 'left',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '16px',
            color: '#303134'
          }),
          menu: (base) => ({
            ...base,
            background: '#FFFFFF',
            boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.15)',
            border: 'none',
            borderRadius: '5px',
            maxHeight: '180px',
            overflowY: 'scroll',
            padding: '15px'
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#F2F7FB' : 'white',
            borderRadius: '5px',
            textAlign: 'left',
            margin: 0,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '25px',
            color: '#303134',
            paddingLeft: '3px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#F2F7FB'
            }
          }),
          valueContainer: (base) => ({
            ...base,
            margin: '0 30px 0 auto',
            '@media screen and (max-width: 1020px)': {
              padding: '0 30px 0 auto'
            },
            '@media screen and (max-width: 420px)': {
              padding: '0 30px 0 auto'
            }
          })
        }
      }}
    />
  );
};

export default CustomAutocomplete;
