import React from 'react';
import { Input } from 'antd';
import EditIcon from '@mui/icons-material/Edit';

type Props = {
  text: string;
  cell: string;
  record: {
    id: string;
  };
  className: string;
  handleUpdateCell: ({ addressId, fields }) => void;
};

export const InputCell = ({ text, cell, record, className, handleUpdateCell }: Props) => {
  const handleChange = (event) => {
    if (!event || !event.target) return;

    const {
      target: { value }
    } = event;
    const sanitizedValue = value ? value.split('#').join('') : '';

    handleUpdateCell({
      addressId: record.id,
      fields: { [cell]: sanitizedValue }
    });
  };

  return (
    <Input
      placeholder="Enter address here"
      className={className}
      value={text}
      onChange={handleChange}
      suffix={<EditIcon style={{ display: text ? 'block' : 'none', color: '#5B6878' }} />}
      required
    />
  );
};
