import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import Modal from 'react-modal';
import classNames from 'classnames';
import {
  copyReport,
  deleteReport,
  updateTitle,
  createReport,
  fetchReports
} from '../../../store/actions';
import Background from '../../../assets/images/Background.png';
import styles from './Result.module.scss';
import ResultTable from '../../../components/BatchProcessor/ResultTable/ResultTable.component';
import FAQComponent from '../../../components/FAQComponent/FAQComponent.component';
import { batchProcessorFAQ } from '../../../components/FAQComponent/FAQComponent.data';
import TitleBadge from '../../../components/TitleBadge/TitleBadge.component';

import { ReactComponent as CloseSvg } from '../../../assets/images/gpr/closeModal.svg';
import { ReactComponent as DownloadSvg } from '../../../assets/images/gpr/result/save.svg';
import { ReactComponent as CopySvg } from '../../../assets/images/redoWhite.svg';
import { ReactComponent as DeleteSvg } from '../../../assets/images/delete.svg';
import { ReactComponent as ViewReportsSvg } from '../../../assets/images/viewBatches.svg';
import spiner from '../../../assets/images/gpr/orange_spiner.gif';
import { ReactComponent as EnvelopeSvg } from '../../../assets/images/envelope.svg';

import { IRootState } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';

const customStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '15px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  }
};

const Result = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = params.id;

  const state = useSelector((state: IRootState) => state.batchAnalysisReducer);
  const { apiKey, keyIsLoaded } = useSelector((state: IRootState) => state.authReducer);
  const { addresses = [], title = '', isLoading, status } = (id && state.history[id]) || {};

  useEffect(() => {
    if (id !== undefined) {
      if (!Object.prototype.hasOwnProperty.call(state.history, id)) {
        navigate('/batch-processor/not-found');
      }
    }
  }, [id]);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    if (addresses.length === 0) {
      const fetchData = async () => {
        const response = await fetch(`${ENVS.PROD}/user/find/${apiKey}`);
        const data = await response.json();
        dispatch(fetchReports(data.br));
        return data;
      };
      fetchData().catch((err) => console.log(err));
    }
  }, []);

  const handleConfirmModal = async () => {
    if (keyIsLoaded) {
      const data = await fetch(`${ENVS.PROD}/user/delete/${apiKey}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: id })
      });
      const json = await data.json();
      dispatch(deleteReport({ id: id }));
      closeModal();
      navigate('/batch-processor');
    }
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const handleCopyReport = () => {
    dispatch(copyReport({ id: id }));
    dispatch(updateTitle({ id: 'new', title: '[name your file]' }));
    navigate(`/batch-processor/new`, { state: { editedID: id } });
  };

  const handleNewBatchReportClick = () => {
    dispatch(createReport({ id: 'new', title: '' }));
    navigate(`/batch-processor/new`);
  };

  const handleCompletedReportsClick = () => navigate('/batch-processor');

  const handleDownloadCSV = () => {
    const dataToParse = addresses.map(({ report, additionalHeader, inputAddress }) => {
      return {
        address: report.address,
        latitude: report.latitude,
        longitude: report.longitude,
        bedrooms: report.bedrooms,
        baths: report.baths,
        building_type: report.building_type,
        look_back_days: report.look_back_days,
        mean: report.mean,
        median: report.median,
        min: report.min,
        max: report.max,
        percentile_25: report.percentile_25,
        percentile_75: report.percentile_75,
        std_dev: report.std_dev,
        samples: report.samples,
        radius_miles: report.radius_miles,
        quickview_url: report.quickview_url,
        updatedAt: report.updatedAt,
        // credits_remaining: report.credits_remaining,
        token: report.token,
        ...(inputAddress ? { input_address: inputAddress } : {}),
        ...(additionalHeader ?? {})
        // links: a.links
      };
    });
    const csv = Papa.unparse(dataToParse);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${title}.csv`);
  };

  useEffect(() => {
    if (status === 'COMPLETED' || status === 'FINISHED') {
      if (keyIsLoaded && id !== undefined) {
        const fetchData = async () => {
          const data = await fetch(`${ENVS.PROD}/user/createBatch/${apiKey}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ report: state.history[id] })
          });
          const json = await data.json();
          return json;
        };
        fetchData().catch((err) => console.log(err));
      }
    }
  }, [status]);

  return (
    <>
      <div
        className={styles.container}
        style={{
          backgroundImage: `url(${Background}), linear-gradient(180deg, #DFE8F2 7.67%, #F4F5F7 100%)`
        }}>
        <div className={styles.contentContainer}>
          <div className={styles.contentHeader}>
            <div className={styles.contentHeaderContainer}>
              <h2 className={styles.contentHeaderTitle}>
                Rentometer Batch Processor
                <TitleBadge text={'BETA'} />
              </h2>
              <div className={styles.contentHeaderSubTitle}>
                Receive rent prices for up to 500 properties
              </div>
            </div>
            <div className={styles.contentHeaderBtnContainer}>
              <a
                className={styles.contentHeaderBtnFeedback}
                href="https://www.rentometer.com/feedback/new"
                rel="noreferrer"
                target="_blank">
                <EnvelopeSvg />
                Send Feedback
              </a>
              <button className={styles.contentHeaderBtn} onClick={handleCompletedReportsClick}>
                <ViewReportsSvg />
                View History
              </button>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.headerContainer}>
                <h1 className={styles.statusTitle}>
                  {isLoading ? (
                    <div>
                      <span style={{ paddingRight: '20px' }}>Processing...</span>
                      <img style={{ width: '25px' }} src={spiner} alt="loading.." />
                    </div>
                  ) : (
                    'Batch Completed!'
                  )}
                </h1>
                <div className={styles.buttonContainer}>
                  <button
                    className={styles.downloadButton}
                    disabled={isLoading}
                    onClick={handleDownloadCSV}>
                    <DownloadSvg />
                    Download CSV file
                  </button>
                  <button
                    className={styles.copyButton}
                    onClick={handleCopyReport}
                    disabled={isLoading}>
                    <CopySvg />
                    Recalculate
                  </button>
                  <button
                    className={styles.deleteButton}
                    onClick={() => openModal()}
                    disabled={isLoading}>
                    <DeleteSvg fill="none" />
                    Delete
                  </button>
                </div>
              </div>
              <div className={styles.headerButtons}>
                <button
                  className={styles.headerButton}
                  onClick={handleNewBatchReportClick}
                  disabled={isLoading}>
                  Build New Batch
                </button>
              </div>
            </div>
            <ResultTable rows={addresses} reportId={id} />
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            shouldCloseOnOverlayClick={false}>
            <CloseSvg className={styles.closeModalIcon} onClick={() => closeModal()} />
            <h1 className={styles.modalTitle}>Delete Batch?</h1>
            <span className={styles.modalDescription}>
              The Batch will be permanently deleted. Please download CSV if you will need a copy in
              the future
            </span>
            <div className={styles.modalButtonContainer}>
              <div className={styles.modalButtonContainerSmall}>
                <button className={styles.modalDownloadButton} onClick={handleDownloadCSV}>
                  Download CSV
                </button>
                <button onClick={() => handleConfirmModal()} className={styles.modalConfirmButton}>
                  Confirm
                </button>
              </div>
              <button onClick={() => closeModal()} className={styles.modalCancelButton}>
                Cancel
              </button>
            </div>
          </Modal>
        </div>
      </div>
      <div className={classNames(styles.container, styles.grayContainer)}>
        <div className={styles.contentContainer}>
          <FAQComponent data={batchProcessorFAQ} />
        </div>
      </div>
    </>
  );
};
export default connect()(Result);
