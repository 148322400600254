export const ENVS = {
  DEV: 'http://localhost:4000',
  STAGE: 'https://toolsapi-stage.rentometer.com:3000',
  PROD: 'https://toolsapi.rentometer.com:3000'
};
export const URLS = {
  REPORT: '/batch-processor/new',
  RESULT: '/batch-processor/:id/result',
  COMPLETED_REPORTS: '/batch-processor'
};
export const CELLS = {
  address: 'address',
  building_type: 'building_type',
  bedrooms: 'bedrooms',
  baths: 'baths',
  look_back_days: 'look_back_days'
};
export const BEDS_OPTIONS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' }
];
export const BATHS_OPTIONS = [
  { value: 'All', label: 'All' },
  { value: '1', label: '1 Only' },
  { value: '1.5+', label: '1 \u00BD or more' }
];
export const BUILDING_TYPE = [
  { value: 'apartment', label: 'Apt/Condo' },
  { value: 'house', label: 'House/Duplex' }
];
export const FILTER_SINGLE_OPTIONS = [
  { value: 'BA', label: 'BA' },
  { value: 'BR', label: 'BR' },
  { value: 'Current rent', label: 'Current rent' },
  { value: 'Avarage rent', label: 'Avarage rent' }
];
export const FILTER_MULTI_OPTIONS = [
  { value: 'BA', label: 'BA' },
  { value: 'BR', label: 'BR' },
  { value: 'Avarage rent', label: 'Avarage rent' },
  { value: 'Count', label: 'Count' },
  { value: 'Total rent', label: 'Total rent' }
];

export const SYSTEM_FIELD = [
  'address',
  'latitude',
  'longitude',
  'bedrooms',
  'baths',
  'building_type',
  'look_back_days',
  'mean',
  'median',
  'min',
  'max',
  'percentile_25',
  'percentile_75',
  'std_dev',
  'samples',
  'radius_miles',
  'quickview_url',
  'updatedAt',
  'token'
];
