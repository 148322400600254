import React, { useEffect, useState, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { Table, Input, Space } from 'antd';
import dateFormat from 'dateformat';
import { Spin } from 'antd';
import { SyncOutlined, CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import CompletedReportDropdown from '../CompletedReportsDropdown/CompletedReportDropdown.component';
import styles from './CompletedReportsTable.module.scss';
import { IRootState } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';

const antIcon = <SyncOutlined style={{ fontSize: 16, color: '#1B74B3' }} spin />;

export const CompletedReportsTable = ({ rows, columns, handleLoading, pagination, onChange }) => {
  const [load, setLoad] = useState(false);
  const [editableId, setEditableId] = useState('');
  const [newTitleValue, setNewTitleValue] = useState('');

  const { apiKey } = useSelector((state: IRootState) => state.authReducer);

  useEffect(() => {
    setLoad(false);
  }, [rows]);

  const handleEditTitle = (id: string, title: string) => {
    setEditableId(id);
    setNewTitleValue(title);
  };

  const onEditSubmited = async () => {
    const response = await fetch(`${ENVS.PROD}/user/renameTitle/${apiKey}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: editableId, title: newTitleValue })
    });
    onEditClose();
    handleLoading();
    setLoad(true);
  };

  const onEditClose = () => {
    setEditableId('');
    setNewTitleValue('');
  };

  const customColumns = [
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      align: 'left',
      minWidth: 250,
      render: (item: string, record: { id: string }) => {
        if (record.id === editableId) {
          return (
            <Space.Compact className={styles.titleContainer} style={{ width: '100%' }}>
              <Input
                className={styles.titleText}
                defaultValue={item}
                value={newTitleValue}
                onChange={(e) => setNewTitleValue(e.target.value)}
              />
              <CloseSquareOutlined className={styles.titleIconClose} onClick={onEditClose} />
              <CheckSquareOutlined className={styles.titleIconSubmit} onClick={onEditSubmited} />
            </Space.Compact>
          );
        } else {
          return <Input className={styles.titleText} value={item} disabled id={record.id} />;
        }
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      align: 'left',
      width: 144,
      render: (item: string) => {
        return (
          <div style={{ display: 'flex' }}>
            <div style={{ textTransform: 'capitalize' }}>{item?.toLowerCase()} </div>
            {item === 'PROCESSING' && <Spin indicator={antIcon} style={{ marginLeft: '12px' }} />}
          </div>
        );
      }
    },
    {
      title: 'Date Completed',
      key: 'finishedAt',
      dataIndex: 'finishedAt',
      align: 'left',
      width: 224,
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.finishedAt).getTime() - new Date(b.finishedAt).getTime(),
      sortDirections: ['ascend'],
      render: (item: string) => {
        return dateFormat(item, 'mmmm dS, yyyy');
      }
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: 70,
      render: (text: string, record: { id: string; title: string }) => {
        return (
          <div className={styles.actions}>
            <CompletedReportDropdown
              recordId={record.id}
              handleEditTitle={handleEditTitle}
              recordTitle={record.title}
            />
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <Table
        locale={{
          emptyText: 'No Processed Batches'
        }}
        columns={columns || customColumns}
        dataSource={rows}
        pagination={pagination}
        onChange={onChange}
        className={styles.completeTable}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};
