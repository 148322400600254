/* eslint-disable no-case-declarations */
import {
  UPDATE_NEARBY_INPUT_FIELD,
  UPDATE_NEARBY_CALCULATE_RESULT,
  RESET_NEARBY_REPORT
} from '../actions';

import { v1 as uuid } from 'uuid';

const defaultReportId = uuid();

const defaultEmptyReport = {
  address: '',
  bedrooms: '',
  bathrooms: '',
  size: '',
  currentRent: '',
  type: ''
};

const initialState = {
  id: defaultReportId,
  title: '',
  data: defaultEmptyReport,
  reports: []
};

export const nearbyCompsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_NEARBY_REPORT:
      return {
        ...state,
        id: payload.newId,
        data: { ...defaultEmptyReport }
      };
    case UPDATE_NEARBY_INPUT_FIELD:
      const { type, value } = payload;
      return {
        ...state,
        data: { ...state.data, [type]: value }
      };

    case UPDATE_NEARBY_CALCULATE_RESULT:
      return {
        ...state,
        data: {
          address: payload.address,
          bedrooms: payload.bedrooms,
          bathrooms: payload.bathrooms,
          size: payload.size,
          currentRent: payload.currentRent,
          type: payload.type
        }
      };
    default:
      return state;
  }
};
