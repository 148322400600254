import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { deleteRow, duplicateRow } from '../../../../store/actions';
import classNames from 'classnames';

import Modal from 'react-modal';

import styles from './CustomDropdown.module.scss';
import 'rc-dropdown/assets/index.css';
import { IRootState } from '../../../../constants/types';

import additional from '../../../../assets/images/gpr/additional.svg';
import { ReactComponent as CloseSVG } from '../../../../assets/images/gpr/closeModal.svg';

export const customStyles: object = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    boxShadow: '0px 3px 30px rgba(0, 0, 0, 0.08)',
    borderRadius: '15px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  }
};

const CustomDropdown = ({ id, openAlertModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { activeReportType } = useSelector((state: IRootState) => state.gprCalculatorReducer);
  const { activeSingleReport } = useSelector((state: IRootState) => state.gprCalculatorReducer);
  const { activeMultiReport } = useSelector((state: IRootState) => state.gprCalculatorReducer);

  const { id: singleReportId, data: singleReportData } = activeSingleReport;
  const { id: multiReportId, data: multiReportData } = activeMultiReport;

  const dispatch = useDispatch();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  function onSelectOption({ key }) {
    switch (key) {
      case 'delete':
        openModal();
        break;
      case 'duplicate':
        dispatch(duplicateRow({ id, activeReportType }));
        break;
      default:
        break;
    }
  }

  const onDeleteItem = () => {
    openAlertModal(true);
    dispatch(deleteRow({ id, activeReportType }));
    closeModal();
  };

  const menu = (
    <Menu
      onClick={onSelectOption}
      style={{
        width: '120px',
        padding: '15px 10px',
        position: 'relative',
        left: 30,
        bottom: 61,
        backgroundColor: '#ffffff',
        boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.15)',
        border: 'none',
        borderRadius: '5px',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#303134'
      }}>
      <MenuItem
        style={{ marginBottom: '3px' }}
        disabled={
          activeReportType === 'single'
            ? singleReportData.length === 10
              ? true
              : false
            : multiReportData.length === 10
            ? true
            : false
        }
        key="duplicate">
        Duplicate
      </MenuItem>
      <MenuItem
        disabled={
          activeReportType === 'single'
            ? singleReportData.length === 1
              ? true
              : false
            : multiReportData.length === 1
            ? true
            : false
        }
        key="delete">
        Delete
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <div
        className={classNames(
          activeReportType === 'single'
            ? styles.dropdownSingleContainer
            : styles.dropdownMultiContainer
        )}>
        <Dropdown
          getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
          trigger={['click']}
          overlay={menu}>
          <img src={additional} alt="additional icon" className="item__additional" />
        </Dropdown>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}>
        <CloseSVG className={styles.closeIcon} onClick={() => closeModal()} />
        <h1 className={styles.modalTitle}>Delete item?</h1>
        <span className={styles.modalDescription}>
          Are you sure you want to delete the information about this
          {activeReportType === 'single' ? ' house' : ' unit'}?
        </span>
        <div className={styles.modalButtonContainer}>
          <button onClick={() => closeModal()} className={styles.closeModalButton}>
            Cancel
          </button>
          <button onClick={onDeleteItem} className={styles.confirmModalButton}>
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CustomDropdown;
