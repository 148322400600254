import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { updateInputField } from '../../../../store/actions';

import more from '../../../../assets/images/gpr/more.svg';
import { IRootState } from '../../../../constants/types';

export const customStyles: object = {
  control: (baseStyles) => ({
    ...baseStyles,
    minWidth: '50px',
    border: '1px solid #b3bcc9',
    borderRadius: '4px',
    textAlign: 'left',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#303134'
  }),
  menu: (base) => ({
    ...base,
    border: 'none',
    background: '#FFFFFF',
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    width: 'max-content',
    minWidth: '100%',
    maxHeight: '140px',
    overflowY: 'scroll',
    padding: '13px 13px 11px 13px'
  }),
  option: () => ({
    borderRadius: '5px',
    textAlign: 'left',
    marginBottom: '2px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '25px',
    color: '#303134',
    paddingLeft: '3px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F2F7FB'
    }
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 0 0 14px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: () => ({
    paddingRight: '12px',
    cursor: 'pointer'
  })
};

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={more} alt="more arrow" style={{ width: '15px' }} />
    </components.DropdownIndicator>
  );
};

const CustomSelect = ({ id, type, option }) => {
  const dispatch = useDispatch();

  const { activeReportType } = useSelector((state: IRootState) => state.gprCalculatorReducer);
  const { activeSingleReport } = useSelector((state: IRootState) => state.gprCalculatorReducer);
  const { activeMultiReport } = useSelector((state: IRootState) => state.gprCalculatorReducer);

  const { id: singleReportId, data: singleReportData } = activeSingleReport;
  const { id: multiReportId, data: multiReportData } = activeMultiReport;

  const onUpdateField = (value) => {
    dispatch(updateInputField({ value, id, type, activeReportType }));
  };

  let currentValue;

  if (activeReportType === 'single') {
    currentValue = singleReportData.filter((item, number) => number === id);
  } else {
    currentValue = multiReportData.filter((item, number) => number === id);
  }

  return (
    <Select
      isClearable={false}
      options={option}
      placeholder=""
      styles={customStyles}
      components={{ DropdownIndicator }}
      onChange={(e) => onUpdateField(e?.value)}
      value={option.filter((item) => {
        return item.value === currentValue[0][type];
      })}
    />
  );
};

export default CustomSelect;
