import React from 'react';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';

import './NearbyCompsMap.scss';
import home from '../../../assets/images/map/home.png';
import marker from '../../../assets/images/map/marker.png';

const containerStyle = {
  width: '100%',
  height: '400px'
};
const greyMap = [
  {
    featureType: 'administrative',
    elementType: 'all',
    stylers: [
      {
        saturation: '-100'
      }
    ]
  },
  {
    featureType: 'administrative.province',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: 65
      },
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: '50'
      },
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: '-100'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'all',
    stylers: [
      {
        lightness: '30'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'all',
    stylers: [
      {
        lightness: '40'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        saturation: -100
      },
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        hue: '#ffff00'
      },
      {
        lightness: -25
      },
      {
        saturation: -97
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [
      {
        lightness: -25
      },
      {
        saturation: -100
      }
    ]
  }
];

function NearbyCompsMap({ centerProperty, markersPosition, miles }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAwopNugn6tNb-1hXDY_ORinP5wV16vTUo'
  });

  const [map, setMap] = React.useState(null);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const getZoomLevel = (miles) => {
    const radius = miles * 1609.344; // convert miles to meters
    const zoomLevel = Math.round(14 - Math.log(radius) / Math.LN2);
    return zoomLevel;
  };

  const zoom = getZoomLevel(miles);

  const onLoad = (map) => {
    const centerLatLng = new window.google.maps.LatLng(centerProperty.lat, centerProperty.lng);
    const circle = new window.google.maps.Circle({
      center: centerLatLng,
      radius: miles * 1609.34 // convert miles to meters
    });
    const bounds = circle.getBounds();
    map.fitBounds(bounds);
    const zoom = map.getZoom();
    setMap(map);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={centerProperty}
      onLoad={onLoad}
      // zoom={zoom}
      onUnmount={onUnmount}
      options={{ styles: greyMap, mapTypeControl: false, streetViewControl: false }}>
      <MarkerF position={centerProperty} icon={home} />
      {markersPosition.map((item, i) => {
        return <MarkerF key={i} position={item} icon={marker} />;
      })}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(NearbyCompsMap);
