import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

import { IRootState } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';

import { ReactComponent as AdditionalSvg } from '../../../assets/images/gpr/additional.svg';
import { ReactComponent as CloseSvg } from '../../../assets/images/gpr/closeModal.svg';

import styles from './CompletedReportsDropdownRent.module.scss';

export const customStyles: object = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '15px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  }
};

const CompletedReportsDropdownRent = ({ report, handleDeleteReport }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [modalIsOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const { apiKey, keyIsLoaded } = useSelector((state: IRootState) => state.authReducer);

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  const formatNumber = (number) => number?.toLocaleString().split(/\s/).join(',');

  function getFormattedDate(currDate) {
    let date = new Date(currDate);
    let year = date.getFullYear().toString().slice(2, 4);
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  }

  const handleDownloadCSV = () => {
    const { nearby_properties = [], search_address = '' } = report || {};
    const dataToParse = nearby_properties.map((item) => {
      return {
        Address: item.address,
        'Property type': item.property_type,
        Distance: item.distance ? item.distance.toFixed(2) : null,
        BR: item.bedrooms,
        BA: item.baths,
        Price: item.price ? `$${formatNumber(item.price)}` : null,
        Sqft: item.sqft ? formatNumber(item.sqft) : null,
        '$/sqft': item.dollar_sqft ? `$${item.dollar_sqft.toFixed(2)}` : null,
        'Last Seen': item.last_seen ? getFormattedDate(item.last_seen) : null,
        Longtitude: item.longitude ? item.longitude.toFixed(4) : null,
        Latitude: item.latitude ? item.latitude.toFixed(4) : null
      };
    });
    const csv = Papa.unparse(dataToParse);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${search_address}.csv`);
  };

  const handleViewReports = (id?: string) => navigate(`/rent-comps/report/${id}`);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleConfirmModal = async () => {
    if (keyIsLoaded) {
      const data = await fetch(`${ENVS.PROD}/rent_comps/delete/${apiKey}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: report.id })
      });
      const json = await data.json();
      closeModal();
      handleDeleteReport(report.id);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const onSelectOption = (option) => {
    if (option === 'view') {
      handleViewReports(report.id);
    } else if (option === 'delete') {
      openModal();
    } else if (option === 'download') {
      handleDownloadCSV();
    }
    closeDropdown();
  };

  const dropdownOptions = [
    { label: 'View', value: 'view' },
    { label: 'Delete', value: 'delete' },
    { label: 'Download', value: 'download' }
  ];

  useEffect(() => {
    if (isDropdownOpen) {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          closeDropdown();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isDropdownOpen]);

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownToggle} onClick={toggleDropdown}>
        <AdditionalSvg width={5} height={20} />
      </div>
      {isDropdownOpen && (
        <div className={styles.dropdownOptions} ref={dropdownRef}>
          {dropdownOptions.map((option) => (
            <div
              key={option.value}
              className={styles.dropdownOption}
              onClick={() => onSelectOption(option.value)}>
              {option.label}
            </div>
          ))}
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}>
        <CloseSvg className={styles.closeModalIcon} onClick={() => closeModal()} />
        <h1 className={styles.modalTitle}>Delete report?</h1>
        <span className={styles.modalDescription}>
          The report will be permanently deleted. Please download CSV if you will need a copy in the
          future.
        </span>
        <div className={styles.modalButtonContainer}>
          <div className={styles.modalButtonContainerSmall}>
            <button className={styles.modalDownloadButton} onClick={() => handleDownloadCSV()}>
              Download CSV
            </button>
            <button onClick={() => handleConfirmModal()} className={styles.modalConfirmButton}>
              Confirm
            </button>
          </div>
          <button onClick={() => closeModal()} className={styles.modalCancelButton}>
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CompletedReportsDropdownRent;
