import { combineReducers } from 'redux';
import { authReducer } from './auth.reducer';
import { batchAnalysisReducer } from './batchAnalisys.reducer';
import { gprCalculatorReducer } from './gprCalculator.reducer';
import { nearbyCompsReducer } from './nearbyComps.reducer';
import { mainReducer } from './main.reducer';

const rootReducer = combineReducers({
  authReducer,
  mainReducer,
  batchAnalysisReducer,
  gprCalculatorReducer,
  nearbyCompsReducer
});

export default rootReducer;
