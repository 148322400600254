import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';

import { IRootState } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';

import 'rc-dropdown/assets/index.css';
import styles from './CompletedReportsDropdownNearby.module.scss';

import { ReactComponent as AdditionalSvg } from '../../../assets/images/gpr/additional.svg';
import { ReactComponent as CloseSvg } from '../../../assets/images/gpr/closeModal.svg';

export const customStyles: object = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '15px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  }
};

const CompletedReportsDropdownNearby = ({ report, handleDeleteReport }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const { apiKey, keyIsLoaded } = useSelector((state: IRootState) => state.authReducer);

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  const handleViewReports = (id?: string) => navigate(`/nearby-comps/report/${id}`);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleConfirmModal = async () => {
    if (keyIsLoaded) {
      const data = await fetch(`${ENVS.PROD}/nearby_comps/delete/${apiKey}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: report.id })
      });
      const json = await data.json();
      closeModal();
      handleDeleteReport(report.id);
    }
  };

  function onSelectOption({ key }) {
    if (key === 'view') {
      handleViewReports(report.id);
    } else if (key === 'delete') {
      openModal();
    } else if (key === 'download') {
      // handleDownloadCSV();
    }
  }

  const menu = (
    <Menu
      onClick={onSelectOption}
      style={{
        width: '120px',
        padding: '15px 10px',
        position: 'relative',
        left: 30,
        bottom: 71,
        backgroundColor: '#ffffff',
        boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.15)',
        border: 'none',
        borderRadius: '5px',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#303134'
      }}>
      <MenuItem style={{ marginBottom: '3px' }} key="view">
        View
      </MenuItem>
      <MenuItem style={{ marginBottom: '3px' }} key="delete">
        Delete
      </MenuItem>
      <MenuItem key="download" disabled>
        Download
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Dropdown
        getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
        trigger={['click']}
        overlay={menu}>
        <div className={styles.dropdownSvgContainer}>
          <AdditionalSvg width={5} height={20} />
        </div>
      </Dropdown>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}>
        <CloseSvg className={styles.closeModalIcon} onClick={() => closeModal()} />
        <h1 className={styles.modalTitle}>Delete batch?</h1>
        <span className={styles.modalDescription}>
          The Batch will be permanently deleted. Please download CSV if you will need a copy in the
          future.
        </span>
        <div className={styles.modalButtonContainer}>
          <div className={styles.modalButtonContainerSmall}>
            <button className={styles.modalDownloadButton}>Download PDF</button>
            <button onClick={() => handleConfirmModal()} className={styles.modalConfirmButton}>
              Confirm
            </button>
          </div>
          <button onClick={() => closeModal()} className={styles.modalCancelButton}>
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CompletedReportsDropdownNearby;
