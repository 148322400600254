import styles from './FAQComponent.module.scss';

import tableFAQ from '../../assets/images/tableFaq.png';
import outputBatchCSV from '../../assets/csv/Batch Processor Example_Output.csv';
import outputRentCompsCSV from '../../assets/csv/Rent Comps Example_Output.csv';

export const mainPageFAQ = [
  {
    question: 'What is the Rentometer Tools platform?',
    answer: (
      <div className={styles.faqAnswer}>
        Rentometer has created ready-to-use tools powered by our rent data to help you grow your
        real estate business. Our Rent Analysis tools will help you analyze rents faster and more
        efficiently. Generate leads for your business and increase brand visibility with our
        Marketing tools.
      </div>
    )
  },
  {
    question: 'How can I access Rentometer Tools?',
    answer: (
      <div className={styles.faqAnswer}>
        A{' '}
        <a href="https://www.rentometer.com/pricing" target="_blank" rel="noreferrer">
          Rentometer Pro
        </a>{' '}
        subscription is required to access Rentometer Tools. You can access Rentometer Tools{' '}
        <button
          className={styles.faqLink}
          onClick={() => {
            const body = document.querySelector('body');
            if (body) {
              body.scrollTo({ top: 0, behavior: 'smooth' });
            }
          }}>
          here
        </button>
        .
      </div>
    )
  },
  {
    question: 'Is there a cost to use Rentometer Tools?',
    answer: (
      <div className={styles.faqAnswer}>
        Some of our Rentometer Tools require credits. All Rentometer Pro subscriptions come with
        1,000+ complementary credits to help get you started. High-volume tool users can purchase
        additional credits as needed in{' '}
        <a href="https://www.rentometer.com/accounts/edit" target="_blank" rel="noreferrer">
          My Account
        </a>
        .
      </div>
    )
  },
  {
    question: 'What types of credits are needed to use Rentometer Tools?',
    answer: (
      <div className={styles.faqAnswer}>
        <div className={styles.faqTable}>
          <img src={tableFAQ} alt="table describe credits usage" />
        </div>
        <div>
          The Rentometer Pro subscription includes 1,000 QuickView credits, 50 Premium credits, and
          200 Pro Reports. <br />
          High-volume tool users can purchase additional credits as needed in{' '}
          <a href="https://www.rentometer.com/accounts/edit" target="_blank" rel="noreferrer">
            My Account
          </a>
          .
        </div>
      </div>
    )
  },
  {
    question: 'How much do QuickView credits cost?',
    answer: (
      <div className={styles.faqAnswer}>
        <ul>
          <li>$99 for 1,000 QuickView credits</li>
          <li>$199 for 2,500 QuickView credits</li>
          <li>$299 for 5,000 QuickView credits</li>
          <li>$499 for 10,000 QuickView credits</li>
          <li>$999 for 50,000 QuickView credits</li>
          <li>$1,499 for 100,000 QuickView credits</li>
        </ul>
        <div>
          High-volume tool users can purchase additional credits as needed in{' '}
          <a href="https://www.rentometer.com/accounts/edit" target="_blank" rel="noreferrer">
            My Account
          </a>
          .
        </div>
      </div>
    )
  },
  {
    question: 'How much do Premium credits cost?',
    answer: (
      <div className={styles.faqAnswer}>
        <ul>
          <li> $99 for 250 Premium credits</li>
          <li>$249 for 1,000 Premium credits</li>
          <li>$899 for 5,000 Premium credits</li>
          <li>$1,499 for 12,500 Premium credits</li>
        </ul>
        <div>
          High-volume tool users can purchase additional credits as needed in{' '}
          <a href="https://www.rentometer.com/accounts/edit" target="_blank" rel="noreferrer">
            My Account
          </a>
          .
        </div>
      </div>
    )
  },
  {
    question: 'How much do Pro Reports cost?',
    answer: (
      <div className={styles.faqAnswer}>
        <ul>
          <li>$29 for 10 Pro Reports</li>
          <li>$99 for 100 Pro Reports</li>
          <li>$199 for 250 Pro Reports</li>
          <li>$899 for 1,500 Pro Reports</li>
          <li>$1,499 for 3,000 Pro Reports</li>
        </ul>
        <div>
          High-volume tool users can purchase additional credits as needed in{' '}
          <a href="https://www.rentometer.com/accounts/edit" target="_blank" rel="noreferrer">
            My Account
          </a>
          .
        </div>
      </div>
    )
  }
];

export const batchProcessorFAQ = [
  {
    question: 'How do I access the Batch Processor tool?',
    answer: (
      <div className={styles.faqAnswer}>
        A Rentometer Pro subscription is required to access all Rentometer Tools, including Batch
        Processor.
      </div>
    )
  },
  {
    question: 'What is the cost to process a Batch report?',
    answer: (
      <div className={styles.faqAnswer}>
        Each address included in the Batch will cost one (1) QuickView credit. A Rentometer Pro
        subscription includes 1,000 QuickView credits.
        <br />
        <br /> (e.g. A file with 75 addresses will cost 75 QuickView credits.)
      </div>
    )
  },
  {
    question: 'If I use all my QuickView credits, can I purchase more?',
    answer: (
      <div className={styles.faqAnswer}>
        Additional QuickView credits can be purchased in{' '}
        <a href="https://www.rentometer.com/accounts/edit" target="_blank" rel="noreferrer">
          My Account.
        </a>
        <br />
        <br />
        QuickView credit pricing:
        <ul>
          <li>$99 for 1,000 QuickView credits</li>
          <li>$199 for 2,500 QuickView credits</li>
          <li>$299 for 5,000 QuickView credits</li>
          <li>$499 for 10,000 QuickView credits</li>
          <li>$999 for 50,000 QuickView credits</li>
          <li>$1,499 for 100,000 QuickView credits</li>
        </ul>
      </div>
    )
  },
  {
    question: 'How do I use the Batch Processor tool?',
    answer: (
      <div className={styles.faqAnswer}>
        <ol>
          <li>Name your file (e.g. Tampa 3 Bedroom Homes)</li>
          <li>Upload a CSV file of up to 500 properties or manually enter them</li>
          <li>Choose your filters for the look back period and building type</li>
          <li>Press “Process” to receive rent statistics</li>
        </ol>
      </div>
    )
  },
  {
    question: 'Will I be charged a credit for an address that has no data in the output file?',
    answer: (
      <div className={styles.faqAnswer}>
        You will not be charged a credit for an address that has no result.
        <br />
        <br />
        For example, sometimes there isn&apos;t rent data available to us which would generate “no
        result” for that address. In this instance, you would not be charged a credit for that
        particular address.
      </div>
    )
  },
  {
    question: 'What is the required input file format?',
    answer: <div className={styles.faqAnswer}>The required input file format is CSV.</div>
  },
  {
    question: 'What are the required fields for the input file?',
    answer: (
      <div className={styles.faqAnswer}>
        The required fields are:
        <ul>
          <li>Address* (street name, city, state)</li>
          <li>Number of bedrooms</li>
        </ul>
        *The preferred format for the address is a single field, however, we provide the option for
        you to combine into a single field upon uploading file
      </div>
    )
  },
  {
    question: 'Are there optional fields for the input file?',
    answer: (
      <div className={styles.faqAnswer}>
        Optional fields include:
        <ul>
          <li>Baths</li>
          <li>Zip code</li>
          <li>Country</li>
          <li>Up to five user-selected fields.</li>
        </ul>
      </div>
    )
  },
  {
    question: 'Are there limits on the input file?',
    answer: (
      <div className={styles.faqAnswer}>
        Yes.
        <ul>
          <li>No more than 500 records</li>
          <li>No larger than 1MB file size</li>
        </ul>
      </div>
    )
  },
  {
    question: 'What data is included in the output file?',
    answer: (
      <div className={styles.faqAnswer}>
        <a href={outputBatchCSV} download={'Batch Processor Example_Output'}>
          View a sample output file here.
        </a>
      </div>
    )
  },
  {
    question: 'Are my Batch Processor reports saved?',
    answer: (
      <div className={styles.faqAnswer}>
        Yes. Your Batch Processor report will be saved to your account and stored under the “View
        History” page in the Batch Processor tool.
      </div>
    )
  },
  {
    question: 'How long are my Batch Processor reports available in “View History”?',
    answer: (
      <div className={styles.faqAnswer}>
        Your Batch Processor reports are available as long as your Pro subscription remains active.
      </div>
    )
  }
];

export const rentCompsFAQ = [
  {
    question: 'How do I access the Rent Comp Report tool?',
    answer: (
      <div className={styles.faqAnswer}>
        A Rentometer Pro subscription is required to access Rentometer Tools, including the Rent
        Comp Report tool.
      </div>
    )
  },
  {
    question: 'What is the cost to use the Rent Comp Report tool?',
    answer: (
      <div className={styles.faqAnswer}>
        Each rent comp report costs one (1) Premium credit. A Rentometer Pro subscription includes
        50 Premium credits.
        <br />
        <br />
        (e.g. If I request a rent comp report for 15 Broad St, Boston, MA and the tool returns 25
        rent comps, one (1) Premium credit would be deducted from my account.)
      </div>
    )
  },
  {
    question: 'If I use all my Premium credits, can I purchase more?',
    answer: (
      <div className={styles.faqAnswer}>
        <ul>
          <li> $99 for 250 Premium credits</li>
          <li>$249 for 1,000 Premium credits</li>
          <li>$899 for 5,000 Premium credits</li>
          <li>$1,499 for 12,500 Premium credits</li>
        </ul>
        <div>
          High-volume tool users can purchase additional credits as needed in{' '}
          <a href="https://www.rentometer.com/accounts/edit" target="_blank" rel="noreferrer">
            My Account
          </a>
          .
        </div>
      </div>
    )
  },
  {
    question: 'What data is included in the output file?',
    answer: (
      <div className={styles.faqAnswer}>
        The rent comp report can be downloaded as a CSV file, and includes the following fields:
        <ul>
          <li>Formatted property address</li>
          <li>Property Type</li>
          <li>Distance from search address</li>
          <li>Bedrooms (BR)</li>
          <li>Bathrooms (Ba)</li>
          <li>Price</li>
          <li>Square footage (Sqft)</li>
          <li>Price per square foot ($/sqft)</li>
          <li>Last seen</li>
          <li>Longitude</li>
          <li>Latitude</li>
        </ul>
        <a href={outputRentCompsCSV} download={'Rent Comps Example_Output'}>
          Download a sample output file.
        </a>
      </div>
    )
  },
  {
    question: 'Are my rent comp reports saved?',
    answer: (
      <div className={styles.faqAnswer}>
        Yes. Your rent comp reports will be saved to your account and stored under the “View
        History” page in the Rent Comp tool.
      </div>
    )
  },
  {
    question: 'How long are my rent comp reports available in “View History”?',
    answer: (
      <div className={styles.faqAnswer}>
        Your rent comp reports are available as long as your Pro subscription remains active.
      </div>
    )
  }
];
