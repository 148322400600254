import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { v1 as uuid } from 'uuid';

import { IRootState, IGPRReportData } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';
import { updateReportId } from '../../../store/actions';

import { CompletedReportsTableGPR } from '../../../components/GPR/CompletedReportsTableGPR/CompletedReportsTableGPR.component';

import styles from './CompletedReportsGPR.module.scss';
import Background from '../../../assets/images/gpr/background.png';

const CompletedReports: React.FC = () => {
  const [data, setData] = useState<IGPRReportData[]>([]);
  const [deleteReport, setDeleteReport] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { apiKey, keyIsLoaded } = useSelector((state: IRootState) => state.authReducer);

  useEffect(() => {
    if (keyIsLoaded) {
      const fetchData = async () => {
        const response = await fetch(`${ENVS.PROD}/gpr/findAllGpr/${apiKey}`);
        const data = await response.json();
        return data;
      };
      fetchData()
        .then((res) => setData(res))
        .catch((err) => console.log(err));
    }
  }, [apiKey, deleteReport]);

  const rows = data.map((item) => {
    return { ...item, key: item.id };
  });

  const handleNewGprReportClick = () => {
    const newId = uuid();
    const activeReportType = 'single';
    dispatch(updateReportId({ activeReportType, newId }));
    navigate('/gpr-calculator/report');
  };

  const handleDeleteReport = useCallback((id) => {
    setDeleteReport(id);
  }, []);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${Background})`
      }}>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.headerContainer}>
              <h1 className={styles.statusTitle}>Completed GPR Reports</h1>
            </div>
            <button className={styles.headerButton} onClick={handleNewGprReportClick}>
              Build New Report
            </button>
          </div>

          <CompletedReportsTableGPR
            columns={null}
            rows={rows}
            handleDeleteReport={handleDeleteReport}
          />
        </div>
      </div>
    </div>
  );
};
export default CompletedReports;
