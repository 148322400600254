import { call, put, takeEvery } from 'redux-saga/effects';
import { getAuthInfo } from '../../api';
import { getAuthInfoSuccess, getAuthInfoFailure, getAuthInfoRequest } from '../actions';
type AuthProps = {
  payload: {
    authKey: string;
  };
};

function* auth(action: AuthProps) {
  try {
    const response = yield call(getAuthInfo, action.payload.authKey);
    yield put(getAuthInfoSuccess(response?.data));
  } catch (error: any) {
    yield put(getAuthInfoFailure(error?.data?.message));
  }
}

function* watchAuthSaga() {
  yield takeEvery(getAuthInfoRequest, auth);
}
export default watchAuthSaga;
