/* eslint-disable react/prop-types */
import React from 'react';
import Select, { components, StylesConfig } from 'react-select';
import { ReactComponent as MorelSvg } from '../../../../assets/images/gpr/more.svg';
const { ValueContainer, Placeholder } = components;

const bathsOptions = [
  { value: '', label: 'All' },
  { value: '1', label: '1 Only' },
  { value: '1.5', label: '1 \u00BD or more' }
];
const bedsOptions = [
  { value: '1', label: '1 Bed' },
  { value: '2', label: '2 Beds' },
  { value: '3', label: '3 Beds' },
  { value: '4', label: '4 Beds' }
];
const OPTIONS = {
  address: [],
  baths: bathsOptions,
  bedrooms: bedsOptions
};

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <MorelSvg width="15px" />
    </components.DropdownIndicator>
  );
};

const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

type Props = {
  cell: string;
  record: {
    id: string;
  };
  currentValue: string;
  handleUpdateCell: ({ addressId, fields }) => void;
};

export const SelectCell = ({ cell, record, currentValue, handleUpdateCell }: Props) => {
  const options = OPTIONS[cell];

  let valueOpt = options[0];
  options.forEach((opt) => {
    if (opt.value === currentValue) {
      return (valueOpt = opt);
    }
  });

  const handleChange = (value) => {
    handleUpdateCell({ addressId: record.id, fields: { [cell]: value.value } });
  };
  const customStyles: any = {
    control: () => ({
      position: 'relative',
      border: '1px solid #B3BCC9',
      borderRadius: '4px',
      padding: '10px 14px 9px 14px',
      outline: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '25px',
      color: '#5B6878',
      '@media screen and (max-width: 1020px)': {
        fontSize: '14px',
        lineHeight: '17px'
      },
      '@media screen and (max-width: 420px)': {
        fontSize: '11px',
        lineHeight: '15px'
      }
    }),
    option: () => ({
      margin: 0,
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '25px',
      color: '#5B6878',
      padding: '12px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#9AA9BB20'
      },
      '@media screen and (max-width: 1020px)': {
        fontSize: '14px',
        lineHeight: '14px',
        padding: '8px'
      },
      '@media screen and (max-width: 420px)': {
        fontSize: '11px',
        lineHeight: '11px',
        padding: '6px'
      }
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition, color: '#5B6878', margin: 0, padding: 0 };
    },
    indicatorSeparator: () => ({
      display: 'none',
      margin: 0,
      padding: 0
    }),
    valueContainer: (base) => ({
      ...base,
      margin: 0,
      padding: 0
    }),
    dropdownIndicator: () => ({
      cursor: 'pointer'
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0
    }),
    placeholder: (base, state) => {
      return {
        ...base,
        margin: 0,
        padding: 0,
        display: state?.selectProps?.value?.value && 'none'
      };
    }
  };

  return (
    <Select
      components={{
        ValueContainer: CustomValueContainer,
        DropdownIndicator: DropdownIndicator
      }}
      placeholder=""
      value={valueOpt}
      onChange={handleChange}
      options={OPTIONS[cell]}
      styles={customStyles}
    />
  );
};
