import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from '@redux-saga/core';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from './saga/index.saga';
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['gprCalculatorReducer', 'batchAnalysisReducer', 'nearbyCompsReducer', 'mainReducer']
};

const sagaMiddleware = createSagaMiddleware();
const requestMiddleware = composeWithDevTools(applyMiddleware(sagaMiddleware));

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore() {
  let store = createStore(persistedReducer, requestMiddleware);
  let persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
}
