import React from 'react';
import styles from './MessageComponents.module.scss';

interface LackOfCreditsMessageProps {
  shouldDisplay: boolean;
}

const LackOfCreditsMessage: React.FC<LackOfCreditsMessageProps> = ({ shouldDisplay }) => {
  if (!shouldDisplay) {
    return null;
  }

  return (
    <div className={styles.creditsAvailabilityContainer}>
      <span className={styles.creditsAvailabilityText}>
        The number of items exceeds the available credits.
        <a
          href="https://www.rentometer.com/accounts/edit"
          target="_blank"
          rel="noreferrer"
          className={styles.creditsAvailabilityLink}>
          Buy More Credits
        </a>
      </span>
    </div>
  );
};

export default LackOfCreditsMessage;
