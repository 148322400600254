import React, { useState, useEffect } from 'react';
import styles from './NearbyCompleteReportInput.module.scss';
import { ReactComponent as FilterSvg } from '../../../assets/images/nearbyComps/filter.svg';
import classNames from 'classnames';

import ListSort from '../CustomDragNDropeList/CustomDtagNDropeList.component';
import NearbyCompsMap from '../NearbyCompsMap/NearbyCpmpsMap.component';
import NearbyDownloadPdf from '../NearbyDownloadPdf/NearbyDownloadPdf.component';
import { INearbyProperty } from '../../../constants/types';

const NearbyCompleteResult = ({ data }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState([]);
  const [dataAfterFilter, setDataAfterFilter] = useState<INearbyProperty[] | []>([]);
  const [latLngPositions, setLatLngPositions] = useState<{ lat: number; lng: number }[]>([]);
  const [radius, setRadius] = useState<number>(0.33);

  const { summary, nearbyData: comps } = data;

  useEffect(() => {
    const dataForFilter = comps?.nearby_properties?.map((item) => item);
    setDataAfterFilter(dataForFilter);
  }, []);

  const centerProperty = {
    lat: +comps?.search_latitude,
    lng: +comps?.search_longitude
  };

  useEffect(() => {
    if (dataAfterFilter?.length > 0) {
      const sortDataWithFilter = (filter, type) => {
        return dataAfterFilter.sort((a, b) => {
          const diffA = Math.abs(a[type] - filter);
          const diffB = Math.abs(b[type] - filter);
          if (isNaN(diffA)) {
            return 1;
          } else if (isNaN(diffB)) {
            return -1;
          } else if (diffA < diffB) {
            return -1;
          } else if (diffA > diffB) {
            return 1;
          } else {
            return 0;
          }
        });
      };

      const sortDate = (a, b) => {
        return Date.parse(b.last_seen) - Date.parse(a.last_seen);
      };
      const sortDistance = (a, b) => {
        return a.distance - b.distance;
      };
      const filterDollarSqft = summary.currentRent / summary.size;

      const filtersArrayCopy = [...filters];
      filtersArrayCopy.reverse().forEach((item, i) => {
        if (item === 'Distance') {
          dataAfterFilter.sort(sortDistance);
        } else if (item === 'Size (sqft)') {
          sortDataWithFilter(+summary.size, 'sqft');
        } else if (item === 'Price (sqft)') {
          sortDataWithFilter(filterDollarSqft, 'dollar_sqft');
        } else if (item === 'Price') {
          sortDataWithFilter(+summary.currentRent, 'price');
        } else if (item === 'Bedrooms') {
          sortDataWithFilter(summary.bedrooms, 'bedrooms');
        } else if (item === 'List Date') {
          dataAfterFilter.sort(sortDate);
        }
      });
      setDataAfterFilter(dataAfterFilter);
      const markersPosition = dataAfterFilter?.slice(0, 5)?.map((item) => {
        return { lat: +item?.latitude, lng: +item?.longitude };
      });
      setLatLngPositions(markersPosition);
    }
  }, [filters]);

  useEffect(() => {
    if (dataAfterFilter?.length > 0) {
      const markersPosition = dataAfterFilter?.slice(0, 5)?.map((item) => {
        return { lat: +item?.latitude, lng: +item?.longitude };
      });
      const miles = dataAfterFilter.slice(0, 5)?.sort((a, b) => {
        return a.distance - b.distance;
      });
      setRadius(miles[4].distance);
      setLatLngPositions(markersPosition);
    }
  }, [dataAfterFilter]);

  const openFilter = () => {
    setShowFilters(true);
  };
  const closeFilter = () => {
    setShowFilters(false);
  };

  const formatNumber = (number) => number?.toLocaleString().split(/\s/).join(',');

  let diffRent;
  if (summary) {
    diffRent = Math.round(((+summary.currentRent - +summary.mean) / +summary.mean) * 100);
  }

  function getFormattedDate(currDate) {
    let date = new Date(currDate);
    let year = date.getFullYear().toString().slice(2, 4);
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  }

  return (
    <div className={styles.content}>
      <div className={styles.contentHeader}>
        <h2 className={styles.contentTitle}>Market QuickView</h2>
        <div className={styles.contentTotal}>
          Total Comps {comps.count} within {summary?.radius_miles.toFixed(2) || 0.33} mi.
        </div>
      </div>
      <div className={styles.addressData}>
        <div className={styles.addressDataItem}>
          <div className={styles.addressDataAmount}>${formatNumber(summary.mean)}</div>
          <div className={styles.addressDataDescription}>Average</div>
        </div>
        <div className={styles.addressDataItem}>
          <div className={styles.addressDataAmount}>${formatNumber(summary.median)}</div>
          <div className={styles.addressDataDescription}>Median</div>
        </div>
        <div className={styles.addressDataItem}>
          <div className={styles.addressDataAmount}>${formatNumber(summary.percentile_25)}</div>
          <div className={styles.addressDataDescription}>25th Percentile</div>
        </div>
        <div className={styles.addressDataItem}>
          <div className={styles.addressDataAmount}>${formatNumber(summary.percentile_75)}</div>
          <div className={styles.addressDataDescription}>75th Percentile</div>
        </div>
        <div className={styles.addressDataItem}>
          <div className={styles.addressDataAmount}>{diffRent}%</div>
          <div className={styles.addressDataDescription}>Difference from the average</div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <NearbyDownloadPdf data={data} dataAfterFilter={dataAfterFilter} diffRent={diffRent} />
      </div>
      <div className={styles.sortedContainer}>
        <div className={styles.sortedHeader}>
          <div className={styles.sortedTitle}>Most Similar Comps</div>
          <button className={styles.sortedFilter} onClick={openFilter}>
            <FilterSvg />
            <div>Filters</div>
          </button>
          <div
            className={classNames(
              styles.sortedFilterMenu,
              showFilters ? styles.sortedFilterMenuIsOpen : null
            )}>
            <ListSort closeFilter={closeFilter} setFilters={setFilters} />
          </div>
        </div>
        <div className={styles.sortedData}>
          <div className={styles.sortedHeaders}>
            <div className={styles.sortedHeadersItem}>Property Address</div>
            <div className={styles.sortedHeadersItem}>Property Type</div>
            <div className={styles.sortedHeadersItem}>Distance</div>
            <div className={styles.sortedHeadersItem}>Bedrooms</div>
            <div className={styles.sortedHeadersItem}>Baths</div>
            <div className={styles.sortedHeadersItem}>Price</div>
            <div className={styles.sortedHeadersItem}>Size (sqft)</div>
            <div className={styles.sortedHeadersItem}>Price (sqft)</div>
            <div className={styles.sortedHeadersItem}>List Date</div>
          </div>

          {dataAfterFilter.map((item, i) => {
            if (i <= 4) {
              return (
                <div key={i} className={styles.sortedItems}>
                  <div className={styles.sortedItem}>
                    {item.address.length > 30 ? `${item.address.slice(0, 30)}...` : item.address}
                    <span className={styles.hide}>
                      <div>{item.address}</div>
                      <div className={styles.hideTriangle}></div>
                    </span>
                  </div>
                  <div className={styles.sortedItem}>
                    {item.property_type ? item.property_type : '-'}
                  </div>
                  <div className={styles.sortedItem}>{item.distance ? item.distance : '-'}</div>
                  <div className={styles.sortedItem}>{item.bedrooms ? item.bedrooms : '-'}</div>
                  <div className={styles.sortedItem}>{item.baths ? item.baths : '-'}</div>
                  <div className={styles.sortedItem}>
                    {item.price ? `$${formatNumber(item.price)}` : '-'}
                  </div>
                  <div className={styles.sortedItem}>
                    {item.sqft ? formatNumber(item.sqft) : '-'}
                  </div>
                  <div className={styles.sortedItem}>
                    {item.dollar_sqft ? `$${item.dollar_sqft.toFixed(2)}` : '-'}
                  </div>
                  <div className={styles.sortedItem}>
                    {item.last_seen ? getFormattedDate(item.last_seen) : '-'}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className={styles.mapContainer}>
        <NearbyCompsMap
          centerProperty={centerProperty}
          markersPosition={latLngPositions}
          miles={radius}
        />
      </div>
    </div>
  );
};

export default NearbyCompleteResult;
