/* eslint-disable no-case-declarations */
import {
  UPDATE_BATCH_ANALYSIS,
  ADD_NEW_ADDRESS,
  DELETE_ADDRESSES,
  UPDATE_TITLE,
  UPDATE_ADDRESS_FIELDS,
  UPDATE_ADDRESS_GLOBAL_FIELD,
  START_BUILD_REPORT,
  COPY_REPORT,
  CREATE_REPORT,
  DELETE_REPORT,
  DELETE_ALL_ADDRESSES,
  FETCH_REPORTS,
  FORMAT_ADRESSES_BEFORE_BUILD
} from '../actions';
import { v1 as uuid } from 'uuid';

const defaultId = uuid();

const defaultEmptyAddress = {
  id: defaultId,
  key: defaultId,
  isLoading: false,
  address: '',
  latitude: '',
  longitude: '',
  bedrooms: 1,
  baths: '',
  building_type: '',
  createdAt: new Date(),
  look_back_days: 365,
  report: {}
};

const defaultBatchAnalysis = {
  id: 'new',
  status: '',
  title: '[name your file]',
  addresses: [{ ...defaultEmptyAddress }],
  isLoading: false,
  createdAt: new Date(),
  updatedAt: null
};

const initialState = {
  history: {
    new: defaultBatchAnalysis
  }
};
export const batchAnalysisReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_BUILD_REPORT:
      return {
        ...state,
        history: {
          ...state.history,
          [payload.id]: state.history[payload.id]
            ? { ...(state.history[payload.id] || {}), ...payload }
            : { ...(state.history['new'] || {}), ...payload },
          ['new']: state.history[payload.id]
            ? { ...(state.history['new'] || {}) }
            : defaultBatchAnalysis
        }
      };
    case UPDATE_BATCH_ANALYSIS:
      return {
        ...state,
        history: {
          ...state.history,
          [payload.id]: { ...(state.history[payload.id] || {}), ...payload }
        }
      };
    case UPDATE_ADDRESS_FIELDS:
      const allAddresses = (state.history[payload.id] || {}).addresses;
      const updatedAddresses = allAddresses.map((a) => {
        let addressToUpdate = a;
        if (payload.addressId === a.id) {
          addressToUpdate = { ...a, ...payload.fields };
          addressToUpdate.updatedAt = new Date();
        }
        return addressToUpdate;
      });
      return {
        ...state,
        history: {
          ...state.history,
          [payload.id]: {
            ...(state.history[payload.id] || {}),
            addresses: updatedAddresses
          }
        }
      };
    case FORMAT_ADRESSES_BEFORE_BUILD:
      const addressesCopy = (state.history[payload.id] || {}).addresses;
      const formatedAdresses = addressesCopy.map((a) => {
        let addressToUpdate = a;
        addressToUpdate = {
          id: a.id,
          key: a.key,
          isLoading: a.isLoading,
          address: a.address,
          latitude: a?.latitude,
          longitude: a?.longitude,
          bedrooms: a.bedrooms,
          baths: a.baths,
          building_type: a.building_type,
          look_back_days: a.look_back_days,
          report: a?.report,
          fileName: a?.fileName,
          fileId: a.fileId,
          createdAt: a.createdAt,
          additionalHeader: a?.additionalHeader,
          inputAddress: a.address
        };
        addressToUpdate.updatedAt = new Date();
        return addressToUpdate;
      });
      return {
        ...state,
        history: {
          ...state.history,
          [payload.id]: {
            ...(state.history[payload.id] || {}),
            addresses: formatedAdresses
          }
        }
      };
    case UPDATE_ADDRESS_GLOBAL_FIELD:
      const updatedFields = state.history[payload.id].addresses.map((item) => {
        item[payload.field] = payload.value;
        return item;
      });
      return {
        ...state,
        history: {
          ...state.history,
          [payload.id]: {
            ...(state.history[payload.id] || {}),
            addresses: updatedFields,
            updatedAt: new Date()
          }
        }
      };
    case ADD_NEW_ADDRESS:
      const addresses = (state.history[payload.id] || {}).addresses || [];
      let newAddress = defaultEmptyAddress;
      newAddress = { ...newAddress, ...(payload.address || {}) };
      newAddress.id = uuid();
      newAddress.key = newAddress.id;
      newAddress.createdAt = new Date();
      addresses.push(newAddress);
      return {
        ...state,
        history: {
          ...state.history,
          [payload.id]: { ...(state.history[payload.id] || {}), addresses: addresses }
        }
      };
    case DELETE_ADDRESSES:
      let cleansedAddresses = ((state.history[payload.id] || {}).addresses || []).filter(
        (a) => !(payload.addresses || []).includes(a.id)
      );
      return {
        ...state,
        history: {
          ...state.history,
          [payload.id]: {
            ...(state.history[payload.id] || {}),
            addresses:
              cleansedAddresses.length || !payload.newId
                ? cleansedAddresses
                : [{ ...defaultEmptyAddress, id: payload.newId, key: payload.newKey }]
          }
        }
      };
    case DELETE_ALL_ADDRESSES:
      return {
        ...state,
        history: {
          ...state.history,
          [payload.id]: { ...(state.history[payload.id] || {}), addresses: [] }
        }
      };
    case UPDATE_TITLE:
      return {
        ...state,
        history: {
          ...state.history,
          [payload.id]: {
            ...(state.history[payload.id] || {}),
            title: payload.title,
            updatedAt: new Date()
          }
        }
      };
    case COPY_REPORT:
      return {
        ...state,
        history: {
          ...state.history,
          new: { ...state.history[payload.id], id: 'new', createdAt: new Date() }
        }
      };
    case CREATE_REPORT:
      return {
        ...state,
        history: {
          ...state.history,
          new: {
            ...defaultBatchAnalysis,
            id: 'new',
            title: payload.title,
            addresses: [{ ...defaultEmptyAddress }],
            createdAt: new Date()
          }
        }
      };
    case DELETE_REPORT:
      return {
        ...state,
        history: Object.keys(state.history)
          .filter((report) => !report.includes(payload.id))
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: state.history[key] });
          }, {})
      };
    case FETCH_REPORTS:
      return {
        ...state,
        history: {
          new: {
            ...defaultBatchAnalysis,
            id: 'new',
            addresses: [{ ...defaultEmptyAddress }],
            createdAt: new Date()
          },
          ...payload?.reduce(function (acc, cur) {
            acc[cur.id] = cur;
            return acc;
          }, {})
        }
      };
    default:
      return state;
  }
};
