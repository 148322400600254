import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setApiKey, getAuthInfoRequest } from '../store/actions';
import { Route, Routes as Switch, Navigate } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import { Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useCookies } from 'react-cookie';

import Report from '../pages/BatchProcessor/Report/Report.page';
import Result from '../pages/BatchProcessor/Result/Result.page';
import CompletedReports from '../pages/BatchProcessor/CompletedReports/CompletedReports.page';

import ReportGPR from '../pages/GPR/ReportGPR/ReportGPR.page';
import ResultGPR from '../pages/GPR/ResultGPR/ResultGPR.page';
import CompletedReportsGPR from '../pages/GPR/CompletedReportsGPR/CompletedReportsGPR.page';

import NearbyCompsReport from '../pages/NearbyComps/NearbyCompsReport/NearbyCompsReport.page';
import CompletedReportsNearby from '../pages/NearbyComps/CompletedReportsNearby/CompletedReportsNearby.page';

import RentComps from '../pages/RentComps/RentCompsMain/RentComps.page';
import CompletedReportsRent from '../pages/RentComps/CompletedReportsRent/CompletedReportsRent.page';

import MainLanding from '../pages/MainLanding/MainLanding.page';
import NotFound from '../pages/Error/Error';
import Modal from 'react-modal';

import { IRootState } from '../constants/types';
import { ENVS } from '../constants/constants';
import { customModalStyles } from '../pages/BatchProcessor/Report/Report.data';
import styles from '../pages/BatchProcessor/Report/Report.module.scss';

export const Routes = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [keyValue, setKeyValue] = useState('');

  const [cookies, setCookie, removeCookie] = useCookies(['rom_api_key']);

  const {
    apiKey,
    keyIsLoaded,
    account_email,
    credits_remaining,
    pro_report_credits_remaining,
    authorized
  } = useSelector((state: IRootState) => state.authReducer);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const cookiesApiKey = cookies?.rom_api_key;
    if (!cookiesApiKey) {
      if (!apiKey && location.pathname.startsWith('/batch-processor')) {
        navigate('/');
      }
    }
  }, []);

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  useEffect(() => {
    if (keyIsLoaded) {
      if (
        !apiKey &&
        !location.pathname.startsWith('/batch-processor') &&
        !(location.pathname === '/')
      ) {
        openModal();
      } else {
        dispatch(getAuthInfoRequest({ authKey: apiKey }));
      }
    }
  }, [apiKey, keyIsLoaded]);

  const setUpApiKey = async () => {
    let userApiKey = cookies?.rom_api_key;
    dispatch(setApiKey(userApiKey));

    if (userApiKey) {
      try {
        const authResponse = await fetch(
          `https://www.rentometer.com/api/v1/auth?api_key=${userApiKey}`
        );
        const authBody = await authResponse.json();
        if (!authResponse.ok) {
          throw new Error('Error: Invalid API Key');
        }

        const responseFromDB = await fetch(
          `${ENVS.PROD}/user/findAndUpdate/${userApiKey}?update=true`,
          {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              account_email: authBody.account_email,
              credits_remaining: authBody.credits_remaining,
              pro_report_credits_remaining: authBody.pro_report_credits_remaining,
              authorized: authBody.authorized
            })
          }
        );
        const json = await responseFromDB.json();

        if (!json.exist) {
          const response = await fetch(`${ENVS.PROD}/user/create`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              id: userApiKey,
              account_email: authBody.account_email,
              credits_remaining: authBody.credits_remaining,
              pro_report_credits_remaining: authBody.pro_report_credits_remaining,
              authorized: authBody.authorized
            })
          });
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    setUpApiKey();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyValue(event.target.value);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleConfirmModal = async () => {
    try {
      const response = await fetch('${ENVS.PROD}/api/reports', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: apiKey,
          user: {
            account_email,
            credits_remaining,
            pro_report_credits_remaining,
            authorized
          }
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setApiKey(keyValue));
      closeModal();
      if (location.pathname === '/') {
        navigate('/batch-processor');
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        shouldCloseOnOverlayClick={false}>
        <div className={styles.apiModalContainer}>
          <CloseOutlined className={styles.closeModalIcon} onClick={() => closeModal()} />
          <div className={styles.apiModalTitle}>API key was not found.</div>
          <p className={styles.apiModalDescription}>
            Please enter it manually.
            <br /> To find your API Key go to{' '}
            <a
              href={'https://www.rentometer.com/rentometer-api/settings?from-batch-tool=1'}
              className={styles.infoDownload}>
              Settings
            </a>
          </p>
          <div className={styles.apiHeadersContainer}>
            <Input
              value={keyValue}
              onChange={handleChange}
              className={styles.apiModalInput}
              placeholder="API Key"
            />
          </div>
          <button onClick={handleConfirmModal} className={styles.confirmModalButton}>
            Confirm
          </button>
        </div>
      </Modal>
      <Switch>
        <Route path="/" element={<MainLanding cookieApiKey={cookies?.rom_api_key} />} />
        <Route path="batch-analysis" element={<Navigate to="/batch-processor" replace />} />
        <Route path="batch-processor">
          <Route path="" element={<CompletedReports />} />
          <Route path=":id/reports" element={<Result />} />
          <Route path=":id" element={<Report />} />
          <Route path="not-found" element={<NotFound />} />
        </Route>
        <Route path="gpr-calculator">
          <Route path="" element={<CompletedReportsGPR />} />
          <Route path="report" element={<ReportGPR openApiModal={openModal} />} />
          <Route path="result/:id" element={<ResultGPR />} />
        </Route>
        <Route path="nearby-comps">
          <Route path="" element={<CompletedReportsNearby />} />
          <Route path="report/:id" element={<NearbyCompsReport openApiModal={openModal} />} />
          <Route path="not-found" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />
        <Route path="rent-comps">
          <Route path="" element={<CompletedReportsRent />} />
          <Route path="report/:id" element={<RentComps openApiModal={openModal} />} />
          <Route path="not-found" element={<NotFound />} />
        </Route>
      </Switch>
    </>
  );
};
