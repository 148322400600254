import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import styles from './MainLanding.module.scss';
import { IRootState } from '../../constants/types';
import { updateToolsType } from '../../store/actions';

import { ReactComponent as ArrowToRight } from '../../assets/images/arrow-right.svg';
import FAQComponent from '../../components/FAQComponent/FAQComponent.component';
import { mainPageFAQ } from '../../components/FAQComponent/FAQComponent.data';

const MainLanding = ({ cookieApiKey }) => {
  const { toolsType } = useSelector((state: IRootState) => state.mainReducer);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleSetToolsType(type: 'market' | 'rent') {
    dispatch(updateToolsType(type));
  }

  return (
    <>
      <main className={styles.toolsMain}>
        <div className={styles.backgroundImage}></div>

        <section className={styles.toolsHeaderSection}>
          <div className={classNames(styles.toolsContent, styles.container)}>
            <div className={styles.toolsHeaderContent}>
              <h2 className={styles.toolsHeaderTitle}>
                Rentometer Tools
                <span className={styles.infoHint}>
                  <span className={classNames(styles.toolsHeaderBadge, styles.infoHintIcon)}>
                    Pro
                  </span>
                  <span className={styles.hide} style={{ top: '-62px' }}>
                    Exclusively available for
                    <br />
                    <a
                      href="https://www.rentometer.com/pricing"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: '#196faf', display: 'inline-block', textDecoration: 'none' }}>
                      Pro
                    </a>{' '}
                    subscribers
                    <div className={styles.hideTriangle}></div>
                  </span>
                </span>
              </h2>
              <h3 className={styles.toolsHeaderSubTitle}>Work smarter and faster</h3>
              <div className={styles.toolsHeaderDescription}>
                Analyze your rental market faster and grow your real estate business efficiently
                with Rentometer Tools.
                <br />
                Exclusively available to <strong>Rentometer Pro</strong> subscribers.
              </div>
            </div>
          </div>
        </section>

        <section className={styles.toolsContentSection}>
          <div className={classNames(styles.toolsContent, styles.container)}>
            <div className={styles.toolsContentMain}>
              <div className={styles.toolsContainerRent}>
                <div className={styles.toolsContentHeaderContainer}>
                  <h2
                    className={classNames(
                      styles.toolsContentTitle,
                      toolsType === 'rent' && styles.toolsContentTitleActive
                    )}
                    onClick={() => handleSetToolsType('rent')}>
                    Rent Analysis Tools
                  </h2>
                  <h2
                    className={classNames(
                      styles.toolsContentTitle,
                      toolsType === 'market' && styles.toolsContentTitleActive
                    )}
                    onClick={() => handleSetToolsType('market')}>
                    Marketing Tools
                  </h2>
                </div>
                <div className={styles.toolsContentDescription}>
                  {toolsType === 'rent'
                    ? 'Save time and money with critical rental insights at your fingertips'
                    : 'Get more leads for your business and increase brand visibility'}
                </div>
                {toolsType === 'rent' ? (
                  <div className={styles.toolsContentRentItems}>
                    <div className={styles.toolsContentRentItem}>
                      <div className={styles.toolsContentRentItemHeader}>
                        <h4 className={styles.toolsContentRentItemTitle}>Batch Processor</h4>
                        <div
                          className={styles.toolsContentRentItemStatus}
                          style={{ color: 'white' }}>
                          coming soon
                        </div>
                      </div>
                      <div className={styles.toolsContentRentItemDescription}>
                        Analyze a large number of <br />
                        rent comps quickly.
                      </div>
                      <div className={styles.toolsContentRentItemBtnContainer}>
                        <a
                          className={classNames(
                            styles.toolsContentRentItemBtn,
                            cookieApiKey
                              ? styles.toolsContentGetStarted
                              : styles.toolsContentLearnMore
                          )}
                          href="https://www.rentometer.com/batch-processor">
                          Learn More
                        </a>
                        {cookieApiKey && (
                          <a
                            className={styles.toolsContentGetStartedBtn}
                            onClick={() => navigate('/batch-processor/new')}>
                            Get Started <ArrowToRight />
                          </a>
                        )}
                      </div>
                    </div>
                    <div className={styles.toolsContentRentItem}>
                      <div className={styles.toolsContentRentItemHeader}>
                        <h4 className={styles.toolsContentRentItemTitle}>Rent Comp Reports</h4>
                        <div
                          className={styles.toolsContentRentItemStatus}
                          style={{ color: 'white' }}>
                          coming soon
                        </div>
                      </div>
                      <div className={styles.toolsContentRentItemDescription}>
                        Enter an address to get all available <br /> nearby rent comps.
                      </div>
                      <div className={styles.toolsContentRentItemBtnContainer}>
                        <a
                          className={classNames(
                            styles.toolsContentRentItemBtn,
                            cookieApiKey
                              ? styles.toolsContentGetStarted
                              : styles.toolsContentLearnMore
                          )}
                          href="https://www.rentometer.com/rent-comps">
                          Learn More
                        </a>
                        {cookieApiKey && (
                          <a
                            className={styles.toolsContentGetStartedBtn}
                            onClick={() => navigate('rent-comps/report/new')}>
                            Get Started <ArrowToRight />
                          </a>
                        )}
                      </div>
                    </div>
                    <div className={styles.toolsContentRentItem}>
                      <div className={styles.toolsContentRentItemHeader}>
                        <h4 className={styles.toolsContentRentItemTitle}>GPR Calculator</h4>
                        <div className={styles.toolsContentRentItemStatus}>coming soon</div>
                      </div>
                      <div className={styles.toolsContentRentItemDescription}>
                        Calculate the gross potential rent for your portfolio in real-time.
                      </div>
                      <div className={styles.toolsContentRentItemBtnContainer}>
                        <a
                          className={classNames(
                            styles.toolsContentRentItemBtn,
                            cookieApiKey
                              ? styles.toolsContentGetStarted
                              : styles.toolsContentLearnMore,
                            styles.disabledLink
                          )}
                          href="#">
                          Learn More
                        </a>
                        {cookieApiKey && (
                          <a
                            className={classNames(
                              styles.toolsContentGetStartedBtn,
                              styles.toolsContentGetStartedBtnInactive
                            )}
                            href="#">
                            Get Started <ArrowToRight />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.toolsContentRentItems}>
                    <div className={styles.toolsContentRentItem}>
                      <div className={styles.toolsContentRentItemHeader}>
                        <h4 className={styles.toolsContentRentItemTitle}>Report Branding</h4>
                        <div
                          className={styles.toolsContentRentItemStatus}
                          style={{ color: 'white' }}>
                          coming soon
                        </div>
                      </div>
                      <div className={styles.toolsContentRentItemDescription}>
                        Add your brand to the top of our reports
                        <br /> to increase visibility.
                      </div>
                      <div className={styles.toolsContentRentItemBtnContainer}>
                        <a
                          className={classNames(
                            styles.toolsContentRentItemBtn,
                            cookieApiKey
                              ? styles.toolsContentGetStarted
                              : styles.toolsContentLearnMore
                          )}
                          href="https://www.rentometer.com/custom-branding">
                          Learn More
                        </a>
                        {cookieApiKey && (
                          <a
                            className={styles.toolsContentGetStartedBtn}
                            href="https://www.rentometer.com/report_brandings">
                            Get Started <ArrowToRight />
                          </a>
                        )}
                      </div>
                    </div>
                    <div className={styles.toolsContentRentItem}>
                      <div className={styles.toolsContentRentItemHeader}>
                        <h4 className={styles.toolsContentRentItemTitle}>Lead Generation</h4>
                        <div
                          className={styles.toolsContentRentItemStatus}
                          style={{ color: 'white' }}>
                          coming soon
                        </div>
                      </div>
                      <div className={styles.toolsContentRentItemDescription}>
                        Generate leads by providing business
                        <br /> prospects with high-quality rent reports.
                      </div>
                      <div className={styles.toolsContentRentItemBtnContainer}>
                        <a
                          className={classNames(
                            styles.toolsContentRentItemBtn,
                            cookieApiKey
                              ? styles.toolsContentGetStarted
                              : styles.toolsContentLearnMore
                          )}
                          href="https://www.rentometer.com/leadgen">
                          Learn More
                        </a>
                        {cookieApiKey && (
                          <a
                            className={styles.toolsContentGetStartedBtn}
                            href="https://www.rentometer.com/leadgen/setup">
                            Get Started <ArrowToRight />
                          </a>
                        )}
                      </div>
                    </div>
                    <div className={styles.toolsContentRentItem}>
                      <div className={styles.toolsContentRentItemHeader}>
                        <h4 className={styles.toolsContentRentItemTitle}>Rentometer Marketplace</h4>
                        <div className={styles.toolsContentRentItemStatus}>
                          No subscription required
                        </div>
                      </div>
                      <div className={styles.toolsContentRentItemDescription}>
                        Boost your visibility and attract customers
                        <br />
                        with a free listing in our Marketplace.
                      </div>
                      <div className={styles.toolsContentRentItemBtnContainer}>
                        <a
                          className={classNames(
                            styles.toolsContentRentItemBtn,
                            cookieApiKey
                              ? styles.toolsContentGetStarted
                              : styles.toolsContentLearnMore
                          )}
                          href="https://directory.rentometer.com/about"
                          target="_blank"
                          rel="noreferrer">
                          Learn More
                        </a>
                        {cookieApiKey && (
                          <a
                            className={classNames(styles.toolsContentGetStartedBtn)}
                            href="https://directory.rentometer.com/"
                            target="_blank"
                            rel="noreferrer">
                            Get Started <ArrowToRight />
                          </a>
                        )}
                      </div>
                    </div>
                    <div className={styles.toolsContentRentItem}>
                      <div className={styles.toolsContentRentItemHeader}>
                        <h4 className={styles.toolsContentRentItemTitle}>Engagement Widget</h4>
                        <div className={styles.toolsContentRentItemStatus}>coming soon</div>
                      </div>
                      <div className={styles.toolsContentRentItemDescription}>
                        Keep website visitors on your site
                        <br /> even longer.
                      </div>
                      <div className={styles.toolsContentRentItemBtnContainer}>
                        <a
                          className={classNames(
                            styles.toolsContentRentItemBtn,
                            cookieApiKey
                              ? styles.toolsContentGetStarted
                              : styles.toolsContentLearnMore,
                            styles.disabledLink
                          )}
                          href="#">
                          Learn More
                        </a>
                        {cookieApiKey && (
                          <a
                            className={classNames(
                              styles.toolsContentGetStartedBtn,
                              styles.toolsContentGetStartedBtnInactive
                            )}
                            href="#">
                            Get Started <ArrowToRight />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className={styles.faq}>
          <div className={styles.container}>
            <FAQComponent data={mainPageFAQ} />
          </div>
        </section>

        <section className={styles.footer}>
          <div className={styles.container}>
            <nav className={styles.toolsFooter}>
              <ul className={styles.toolsFooterList}>
                <li className={styles.toolsFooterItem}>
                  <a href="https://www.rentometer.com/about" target="_blank" rel="noreferrer">
                    About Us
                  </a>
                </li>
                <li className={styles.toolsFooterItem}>
                  <a
                    href="https://www.rentometer.com/feedback/new"
                    target="_blank"
                    rel="noreferrer">
                    Contact Us
                  </a>
                </li>
                <li className={styles.toolsFooterItem}>
                  <a
                    href="https://www.rentometer.com/terms-of-use"
                    target="_blank"
                    rel="noreferrer">
                    Terms of Use
                  </a>
                </li>
                <li className={styles.toolsFooterItem}>
                  <a
                    href="https://www.rentometer.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer">
                    Privacy Policy
                  </a>
                </li>
                <li className={styles.toolsFooterItem}>
                  <a href="https://www.rentometer.com" target="_blank" rel="noreferrer">
                    Back to Rentometer site
                  </a>
                </li>
              </ul>
            </nav>
            <div className={styles.copyRight}>Copyright &copy;2023 Rentometer, Inc</div>
          </div>
        </section>
      </main>
    </>
  );
};

export default MainLanding;
