import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import classNames from 'classnames';
import { ReactComponent as CloseSvg } from '../../../assets/images/gpr/closeModal.svg';
import { ReactComponent as SortSvg } from '../../../assets/images/rent/rentSort.svg';
import { ReactComponent as DownloadSvg } from '../../../assets/images/gpr/result/save.svg';
import Background from '../../../assets/images/nearbyComps/nearbyBg.png';
import { ReactComponent as ViewReportsSvg } from '../../../assets/images/viewBatches.svg';
import { ReactComponent as EnvelopeSvg } from '../../../assets/images/envelope.svg';
import spiner from '../../../assets/images/gpr/orange_spiner.gif';
import Modal from 'react-modal';
import { v1 as uuid } from 'uuid';

import { IGoogleAddress, ITableData, IApiModal, IRootState } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';
import styles from './RentComps.module.scss';

import { getAuthInfoRequest } from '../../../store/actions';

import { customStyles } from '../../BatchProcessor/Report/Report.data';
import FAQComponent from '../../../components/FAQComponent/FAQComponent.component';
import { rentCompsFAQ } from '../../../components/FAQComponent/FAQComponent.data';
import CustomGoogleAutocomplete from '../../../components/RentComps/CustomGoogleAutocomplete/CustomGoogleAutocomplete.component';
import TitleBadge from '../../../components/TitleBadge/TitleBadge.component';

type SortOrder = 'asc' | 'desc';

type SortConfig = {
  key: string;
  order: SortOrder;
};

const propertyTypes = [
  { value: 'house', label: 'Houses/SFR' },
  { value: 'apartment', label: 'Apt/Condo' },
  { value: '', label: 'All types' }
];
const bedTypes = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' }
];
const bathTypes = [
  { value: '1', label: '1' },
  { value: '1.5', label: '1+' },
  { value: '', label: 'All' }
];

const headers = [
  { key: 'address', label: 'Property Address' },
  { key: 'property_type', label: 'Prop Type' },
  { key: 'distance', label: 'Distance' },
  { key: 'bedrooms', label: 'BR' },
  { key: 'baths', label: 'BA' },
  { key: 'price', label: 'Price' },
  { key: 'sqft', label: 'Sqft' },
  { key: 'dollar_sqft', label: '$/sqft' },
  { key: 'last_seen', label: 'Last Seen' },
  { key: 'longitude', label: 'Longitude' },
  { key: 'latitude', label: 'Latitude' }
];

const NearbyComps = ({ openApiModal }: IApiModal) => {
  const [address, setAddress] = useState<string | null>(null);
  const [fetchedAddress, setFetchedAddress] = useState('');
  const [beds, setBeds] = useState<number | null | undefined>(null);
  const [baths, setBaths] = useState<string | null | undefined>(null);
  const [type, setType] = useState<string | null | undefined>(null);
  const [tableData, setTableData] = useState<ITableData | null>(null);
  const [errorText, setErrorText] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const user = useSelector((state: IRootState) => state.authReducer);

  const [isOpenProcessModal, setIsOpenProcessModal] = useState(false);
  const [numberOfComps, setNumberOfComps] = useState(0);
  const [compsRadius, setCompsRadius] = useState(3);

  const [isLoading, setIsLoading] = useState(false);
  const [isRequestError, setIsRequestError] = useState(false);
  const [isReportLoaded, setIsReportLoaded] = useState(false);

  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const rentCompsId = params.id;

  useEffect(() => {
    if (rentCompsId !== 'new') {
      const fetchData = async () => {
        setIsLoading(true);
        const response = await fetch(
          `${ENVS.PROD}/rent_comps/find?id=${user.apiKey}&reportId=${rentCompsId}`
        );
        const data = await response.json();
        return data[0];
      };
      fetchData()
        .then((res) => {
          setAddress(res.search_address);
          setFetchedAddress(res.search_address);
          setBeds(res.bedrooms);
          setBaths(res.bathrooms);
          setType(res.buildingType);
          setTableData(res);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setNumberOfComps(0);
          setCompsRadius(3);
          setCurrentPage(1);
          setIsReportLoaded(true);
          setIsLoading(false);
        });
    }
  }, [rentCompsId]);

  useEffect(() => {
    dispatch(getAuthInfoRequest({ authKey: user.apiKey }));
  }, [isOpenProcessModal]);

  const openProcessModal = async () => {
    setErrorText(null);
    let bathsValue = baths ? `&baths=${baths + ''}` : '';
    let typeValue = type ? `&building_type=${type}` : '';
    try {
      const response = await fetch(
        `https://www.rentometer.com/api/v1/summary?api_key=p4Jx9oB84MzH1PriZbBR1g&address=${address}&bedrooms=${beds}${bathsValue}${typeValue}`
      );
      const data = await response.json();
      if (response.ok) {
        setNumberOfComps(data.samples);
        setCompsRadius(data.radius_miles);
        setIsOpenProcessModal(true);
      } else {
        setErrorText(data.errors[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const closeProcessModal = () => setIsOpenProcessModal(false);

  const formatNumber = (number) => number?.toLocaleString().split(/\s/).join(',');

  function getFormattedDate(currDate) {
    let date = new Date(currDate);
    let year = date.getFullYear().toString().slice(2, 4);
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  }

  const handleDownloadCSV = () => {
    const { nearby_properties = [], search_address = '' } = tableData || {};
    const dataToParse = nearby_properties.map((item) => {
      return {
        Address: item.address,
        'Property type': item.property_type,
        Distance: item.distance ? item.distance.toFixed(2) : null,
        BR: item.bedrooms,
        BA: item.baths,
        Price: item.price ? `$${formatNumber(item.price)}` : null,
        Sqft: item.sqft ? formatNumber(item.sqft) : null,
        '$/sqft': item.dollar_sqft ? `$${item.dollar_sqft.toFixed(2)}` : null,
        'Last Seen': item.last_seen ? getFormattedDate(item.last_seen) : null,
        Longitude: item.longitude ? item.longitude.toFixed(4) : null,
        Latitude: item.latitude ? item.latitude.toFixed(4) : null
      };
    });
    const csv = Papa.unparse(dataToParse);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${search_address}.csv`);
  };

  const onCalculate = async () => {
    setErrorText(null);
    setIsLoading(true);

    const createdId = uuid();
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        reportId: createdId,
        address: address,
        bedrooms: beds,
        bathrooms: baths,
        buildingType: type
      })
    };

    try {
      const response = await fetch(`${ENVS.PROD}/rent_comps/create/${user.apiKey}`, requestOptions);
      const data = await response.json();
      if (response.ok) {
        navigate(`/rent-comps/report/${createdId}`);
      } else {
        setErrorText(data.errors[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeProcessModal();
      setIsLoading(false);
      setIsReportLoaded(true);
    }
  };

  const handleResetReport = () => {
    setAddress(null);
    setFetchedAddress('');
    setBeds(null);
    setBaths(null);
    setType(null);
    setTableData(null);
    setErrorText(null);
    setSortConfig(null);
    setIsReportLoaded(false);
    setCurrentPage(1);
    navigate('/rent-comps/report/new');
  };

  const handleViewReportClick = () => {
    navigate('/rent-comps');
  };

  const sortTable = (key: string) => {
    // Disable sorting for 'last_seen', 'longitude', and 'latitude' columns
    if (['last_seen', 'longitude', 'latitude'].includes(key)) {
      return;
    }

    let order: SortOrder = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.order === 'asc') {
      order = 'desc';
    }
    setSortConfig({ key, order });
  };

  const sortedData = (currentTableData) => {
    if (currentTableData) {
      if (sortConfig !== null) {
        const { key, order } = sortConfig;
        const multiplier = order === 'asc' ? 1 : -1;

        return [...currentTableData.nearby_properties].sort((a, b) => {
          const propA = key === 'property_type' ? a[key].toLowerCase() : a[key];
          const propB = key === 'property_type' ? b[key].toLowerCase() : b[key];

          return propA > propB ? multiplier : propA < propB ? -multiplier : 0;
        });
      }
      return currentTableData.nearby_properties;
    }
  };

  const getSortIcon = (key) => {
    if (key === 'last_seen' || key === 'longitude' || key === 'latitude') {
      return null;
    } else {
      return <SortSvg className={styles.resultTableSvg} />;
    }
  };

  const handleAddressChange = async (e) => {
    setAddress(e);
  };

  // Pagination

  const ITEMS_PER_PAGE = 20;

  const sortedAndPaginatedData = (currentTableData) => {
    const sortedDataArray = sortedData(currentTableData);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedDataArray.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil((tableData?.nearby_properties?.length || 0) / ITEMS_PER_PAGE);

  const lackOfAPIkeyMessage = user.authorized ? null : (
    <div className={styles.creditsAvailabilityContainer}>
      <span className={styles.creditsAvailabilityText}>
        To run the report, please enter the API key.
        <span onClick={openApiModal} className={styles.creditsAvailabilityLink}>
          Set API key
        </span>
      </span>
    </div>
  );

  const lackOfCreditsMessage =
    user.premium_credits_remaining !== 0 ? null : (
      <div className={styles.creditsAvailabilityContainer}>
        <span className={styles.creditsAvailabilityText}>
          Lack of Premium credits.
          <a
            href="https://www.rentometer.com/accounts/edit"
            target="_blank"
            rel="noreferrer"
            className={styles.creditsAvailabilityLink}>
            Buy More Credits
          </a>
        </span>
      </div>
    );

  const errorMessage = !errorText ? null : (
    <div className={styles.creditsAvailabilityContainer}>
      <span className={styles.creditsAvailabilityText}>{errorText}. Please, try again.</span>
    </div>
  );

  return (
    <>
      <div
        className={styles.container}
        style={{
          backgroundImage: `url(${Background})`
        }}>
        <div className={styles.contentContainer}>
          <div className={styles.contentHeader}>
            <div className={styles.contentHeaderContainer}>
              <h2 className={styles.contentHeaderTitle}>
                Rentometer Tools: Rent Comp Report
                <TitleBadge text={'BETA'} />
              </h2>
              <div className={styles.contentHeaderSubTitle}>
                To receive all available rent comps, enter an address and then select the property
                type and number of bedrooms.
              </div>
            </div>
            <div className={styles.contentHeaderBtnContainer}>
              <a
                className={styles.contentHeaderBtnFeedback}
                href="https://www.rentometer.com/feedback/new"
                rel="noreferrer"
                target="_blank">
                <EnvelopeSvg />
                Send Feedback
              </a>
              <button className={styles.contentHeaderBtn} onClick={handleViewReportClick}>
                <ViewReportsSvg />
                View History
              </button>
            </div>
          </div>

          <div className={styles.fullContent}>
            <div className={styles.nearbyContent}>
              <div className={styles.item}>
                <div className={styles.item__address}>
                  <CustomGoogleAutocomplete
                    defaultAddress={fetchedAddress}
                    handleAddressChange={handleAddressChange}
                    handleResetReport={handleResetReport}
                  />
                </div>
                <div className={styles.item__bedrooms}>
                  <div className={styles.item__Label}>Property Type</div>
                  <div className={styles.radioGroup}>
                    {propertyTypes.map((item) => (
                      <label
                        key={item.value}
                        className={classNames(
                          styles.item_radio,
                          type === item.value ? styles.item_radio_active : ''
                        )}>
                        <input
                          type="radio"
                          name="buildingType"
                          value={item.value}
                          checked={item.value === type}
                          onChange={(e) => setType(e.target.value)}
                        />
                        {item.label}
                      </label>
                    ))}
                  </div>
                </div>
                <div className={styles.item__bedrooms}>
                  <div className={styles.item__Label}>Number of bedrooms </div>
                  <div className={styles.radioGroup}>
                    {bedTypes.map((item) => (
                      <label
                        key={item.value}
                        className={classNames(
                          styles.item_radio,
                          beds === item.value ? styles.item_radio_active : ''
                        )}>
                        <input
                          type="radio"
                          name="beds"
                          value={item.value}
                          checked={item.value === beds}
                          onChange={(e) => setBeds(+e.target.value)}
                        />
                        {item.label}
                      </label>
                    ))}
                  </div>
                </div>
                <div className={styles.item__bedrooms}>
                  <div className={styles.item__Label}>Number of bathrooms (optional)</div>
                  <div className={styles.radioGroup}>
                    {bathTypes.map((item) => (
                      <label
                        key={item.value}
                        className={classNames(
                          styles.item_radio,
                          baths === item.value ? styles.item_radio_active : ''
                        )}>
                        <input
                          type="radio"
                          name="baths"
                          value={item.value}
                          checked={item.value === baths}
                          onChange={(e) => setBaths(e.target.value)}
                        />
                        {item.label}
                      </label>
                    ))}
                  </div>
                </div>
              </div>

              <div className={styles.nearbyButtonContainer}>
                <div className={styles.creditBalanceContainer}>
                  <div className={styles.creditBalance}></div>
                  <div className={styles.creditBalance}>
                    Premium credit balance: {user.premium_credits_remaining}
                  </div>
                  <div>
                    <a
                      className={styles.creditBalanceBuy}
                      href="https://www.rentometer.com/accounts/edit"
                      target="_blank"
                      rel="noopener noreferrer">
                      Purchase more credits
                    </a>
                  </div>
                </div>
                <button
                  className={styles.nearbyButton}
                  onClick={openProcessModal}
                  disabled={
                    !address ||
                    !beds ||
                    !user.authorized ||
                    !user.premium_credits_remaining ||
                    type === null
                  }>
                  Get Rent Comps
                </button>
                <Modal
                  isOpen={isOpenProcessModal}
                  onRequestClose={closeProcessModal}
                  style={customStyles}>
                  <CloseSvg
                    className={styles.processModalClose}
                    onClick={() => closeProcessModal()}
                  />
                  <h1 className={styles.processModalTitle}>Start process</h1>
                  <span className={styles.processModalDescriptionText}>
                    There are {numberOfComps} rent comps available within a {compsRadius} mile
                    radius of your subject address.
                  </span>
                  <span className={styles.processModalDescription}>
                    Process cost: 1 Premium credit
                  </span>
                  <span className={styles.processModalDescription}>
                    Premium credit balance: <strong>{user.premium_credits_remaining}</strong>
                  </span>
                  <div className={styles.modalButtonContainer}>
                    <button
                      onClick={() => closeProcessModal()}
                      className={styles.closeProcessModalButton}>
                      Cancel
                    </button>
                    <button
                      onClick={() => onCalculate()}
                      className={styles.confirmProcessModalButton}>
                      Confirm
                    </button>
                  </div>
                </Modal>
              </div>
              {lackOfAPIkeyMessage}
              {lackOfCreditsMessage}
              {errorMessage}
            </div>

            {isLoading ? (
              <div style={{ margin: '150px 0' }} className="spiner-container">
                <img src={spiner} alt="loading.." />
              </div>
            ) : isReportLoaded ? (
              <div>
                <div className={styles.resultsHeader}>
                  <h2>Rent Comps</h2>
                  <button className={styles.nearbyButtonDownload} onClick={handleDownloadCSV}>
                    <DownloadSvg />
                    Download CSV file
                  </button>
                </div>
                <div className={styles.resultDescription}>
                  <div className={styles.resultDescriptionItem}>
                    Searched address: <strong>{tableData?.search_address}</strong>
                  </div>
                  <div className={styles.resultDescriptionItem}>
                    Rent Comps Found: <strong>{tableData?.count}</strong>
                  </div>
                </div>
                <div className={styles.nearbyTableContainer}>
                  <table className={styles.nearbyTable}>
                    <thead>
                      <tr>
                        {headers.map((header) => (
                          <th key={header.key}>
                            <div
                              className={styles.resultTableLabel}
                              onClick={() => sortTable(header.key)}>
                              {header.label} {getSortIcon(header.key)}
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData && tableData.nearby_properties
                        ? sortedAndPaginatedData(tableData).map((item, i) => {
                            return (
                              <tr key={i}>
                                <td
                                  className={styles.resultTableLabelAddress}
                                  style={{ textAlign: 'left', minWidth: '190px' }}>
                                  {item.address}
                                </td>
                                <td>{item.property_type}</td>
                                <td>{item.distance ? item.distance.toFixed(2) : null}</td>
                                <td>{item.bedrooms}</td>
                                <td>{item.baths}</td>
                                <td>{item.price ? `$${formatNumber(item.price)}` : null}</td>
                                <td>{item.sqft ? formatNumber(item.sqft) : '-'}</td>
                                <td>
                                  {item.dollar_sqft ? `$${item.dollar_sqft.toFixed(2)}` : '-'}
                                </td>
                                <td>{item.last_seen ? getFormattedDate(item.last_seen) : null}</td>
                                <td>{item.longitude ? item.longitude.toFixed(4) : null}</td>
                                <td>{item.latitude ? item.latitude.toFixed(4) : null}</td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>

                {/* Pagination */}
                <div className={styles.pagination}>
                  <button
                    onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                    disabled={!tableData || currentPage === 1}
                    className={styles.paginationItem}>
                    <svg
                      className={styles.paginationItemIcon}
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none">
                      <path
                        d="M4.15545 6.68872L9.22345 1.62072C9.28459 1.55943 9.36253 1.51765 9.44742 1.50068C9.53231 1.48371 9.62033 1.4923 9.70033 1.52537C9.78033 1.55844 9.84873 1.6145 9.89686 1.68645C9.94498 1.75841 9.97068 1.84303 9.9707 1.92959L9.9707 12.0673C9.97068 12.1539 9.94498 12.2385 9.89686 12.3105C9.84873 12.3824 9.78033 12.4385 9.70033 12.4716C9.62033 12.5046 9.53231 12.5132 9.44742 12.4963C9.36253 12.4793 9.28459 12.4375 9.22345 12.3762L4.15545 7.30734C4.07343 7.2253 4.02736 7.11404 4.02736 6.99803C4.02736 6.88202 4.07343 6.77076 4.15545 6.68872Z"
                        fill="#D2D5D8"
                      />
                    </svg>
                  </button>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentPage(index + 1)}
                      disabled={!tableData || currentPage === index + 1}
                      className={classNames(
                        styles.paginationItem,
                        currentPage === index + 1 ? styles.paginationActive : ''
                      )}>
                      {index + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
                    disabled={!tableData || currentPage === totalPages}
                    className={styles.paginationItem}>
                    <svg
                      className={styles.paginationItemIcon}
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none">
                      <path
                        d="M9.84455 7.31128L4.77655 12.3793C4.71541 12.4406 4.63747 12.4823 4.55258 12.4993C4.46769 12.5163 4.37967 12.5077 4.29967 12.4746C4.21967 12.4416 4.15127 12.3855 4.10314 12.3135C4.05502 12.2416 4.02932 12.157 4.0293 12.0704L4.0293 1.93266C4.02932 1.84609 4.05502 1.76147 4.10314 1.68951C4.15127 1.61756 4.21967 1.5615 4.29967 1.52843C4.37967 1.49536 4.46769 1.48677 4.55258 1.50374C4.63747 1.52071 4.71541 1.56249 4.77655 1.62378L9.84455 6.69266C9.92657 6.7747 9.97264 6.88596 9.97264 7.00197C9.97264 7.11798 9.92657 7.22924 9.84455 7.31128Z"
                        fill="#D2D5D8"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={classNames(styles.container, styles.grayContainer)}>
        <div className={styles.contentContainer}>
          <FAQComponent data={rentCompsFAQ} />
        </div>
      </div>
    </>
  );
};

export default NearbyComps;
