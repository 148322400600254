import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { getReportSummary } from '../../api';
import {
  buildReportRequest,
  updateAddressFields,
  updateBatchAnalysis,
  startBuildReport
} from '../actions';

type ReportSummaryProps = {
  payload: {
    authKey: string;
    addresses: [];
    id: string;
    batchAnalysisId: string;
  };
};

interface Address {
  id: string;
  address: string;
  bedrooms: string;
  baths: string;
  building_type: string;
  look_back_days: string;
}

function* buildReport(action: ReportSummaryProps) {
  const addresses = action.payload.addresses;
  const batchAnalysisId = action.payload.batchAnalysisId;
  try {
    yield put(
      startBuildReport({
        id: batchAnalysisId,
        status: 'PROCESSING',
        isLoading: true,
        startedAt: new Date()
      })
    );

    for (const i in addresses) {
      const a: Address = addresses[i];
      yield put(
        updateAddressFields({
          id: batchAnalysisId,
          addressId: a.id,
          fields: {
            report: {
              isLoading: true,
              status: 'REQUEST',
              createdAt: new Date()
            }
          }
        })
      );
      try {
        const response = yield call(
          getReportSummary,
          action.payload.authKey,
          encodeURI(
            `&address=${a.address}&bedrooms=${a.bedrooms}&baths=${a.baths}&building_type=${a.building_type}&look_back_days=${a.look_back_days}&tools=batch`
          )
        );

        if (addresses.length > 50) {
          yield delay(700);
        } else if (addresses.length > 25) {
          yield delay(300);
        } else {
          yield delay(100);
        }

        yield put(
          updateAddressFields({
            id: batchAnalysisId,
            addressId: a.id,
            fields: {
              report: {
                isLoading: false,
                status: 'SUCCESS',
                updatedAt: new Date(),
                address: response.data.address,
                latitude: response.data.latitude,
                longitude: response.data.longitude,
                bedrooms: response.data.bedrooms,
                baths: response.data.baths,
                building_type: response.data.building_type,
                look_back_days: response.data.look_back_days,
                mean: response.data.mean,
                median: response.data.median,
                min: response.data.min,
                max: response.data.max,
                percentile_25: response.data.percentile_25,
                percentile_75: response.data.percentile_75,
                std_dev: response.data.std_dev,
                samples: response.data.samples,
                radius_miles: response.data.radius_miles,
                quickview_url: response.data.quickview_url,
                // credits_remaining: response.data.credits_remaining,
                token: response.data.token,
                links: response.data.links
              }
            }
          })
        );
      } catch (e) {
        yield put(
          updateAddressFields({
            id: batchAnalysisId,
            addressId: a.id,
            fields: {
              report: {
                isLoading: false,
                status: 'FAILURE',
                error: e,
                updatedAt: new Date(),
                address: a.address,
                latitude: '',
                longitude: '',
                bedrooms: '-',
                baths: '-',
                building_type: '-',
                look_back_days: '-',
                mean: '-',
                median: '-',
                min: 0,
                max: 0,
                percentile_25: 0,
                percentile_75: 0,
                std_dev: 0,
                samples: 0,
                radius_miles: 0,
                quickview_url: '-',
                // credits_remaining: response.data.credits_remaining,
                token: '',
                links: []
              }
            }
          })
        );
      }
    }
    yield put(
      updateBatchAnalysis({
        id: batchAnalysisId,
        status: 'COMPLETED',
        finishedAt: new Date(),
        error: null,
        isLoading: false
      })
    );
  } catch (error) {
    yield put(
      updateBatchAnalysis({
        id: batchAnalysisId,
        status: 'FINISHED',
        finishedAt: new Date(),
        error: error
      })
    );
  }
}

function* watchBatchAnalysisSaga() {
  yield takeEvery(buildReportRequest, buildReport);
}

export default watchBatchAnalysisSaga;
