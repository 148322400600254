/* eslint-disable no-case-declarations */
import { UPDATE_TOOLS_TYPE } from '../actions';

const initialState = {
  toolsType: 'rent' as 'rent' | 'market'
};

export const mainReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_TOOLS_TYPE:
      return {
        ...state,
        toolsType: payload
      };
    default:
      return state;
  }
};
