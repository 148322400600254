import React from 'react';
import classNames from 'classnames';
import styles from './ProgressBat.module.scss';

const steps = [0, 1, 2];

export default function HorizontalLinearStepper({ page }) {
  return (
    <div className={styles.stepperContainer}>
      {steps.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className={classNames(
                styles.step,
                index === page ? styles.active : null,
                index < page ? styles.done : null
              )}></div>
            {index === 2 ? null : (
              <div className={styles.lineContainer}>
                <span
                  className={classNames(
                    styles.line,
                    index <= page - 1 ? styles.activeLine : null
                  )}></span>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
