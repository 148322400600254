import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { v1 as uuid } from 'uuid';

import { IRootState, INearbyFetchData } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';
import { resetNearbyReport } from '../../../store/actions';

import { CompletedReportsTableNearby } from '../../../components/NearbyComps/CompletedReportsTableNearby/CompletedReportsTableNearby.component';

import styles from './CompletedReportsNearby.module.scss';
import Background from '../../../assets/images/nearbyComps/nearbyBg.png';
import spiner from '../../../assets/images/gpr/orange_spiner.gif';

const CompletedReportsNearby = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<INearbyFetchData[] | []>([]);
  const [deleteReport, setDeleteReport] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { apiKey, keyIsLoaded } = useSelector((state: IRootState) => state.authReducer);

  useEffect(() => {
    if (keyIsLoaded) {
      setIsLoading(true);
      const fetchData = async () => {
        try {
          const response = await fetch(`${ENVS.PROD}/nearby_comps/findAll/${apiKey}`);
          if (response.ok) {
            const data = await response.json();
            setData(data);
            setIsLoading(false);
            return data;
          } else {
            throw new Error('Failed to fetch data');
          }
        } catch (err) {
          setIsLoading(false);
          console.error(err);
        }
      };

      fetchData();
    }
  }, [apiKey, deleteReport]);

  const rows = data.map((item) => {
    return { ...item, key: item.id };
  });

  const handleNewGprReportClick = () => {
    const newId = uuid();
    dispatch(resetNearbyReport({ newId }));
    navigate(`/nearby-comps/report/new`);
  };

  const handleDeleteReport = useCallback((id) => {
    setDeleteReport(id);
  }, []);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${Background})`
      }}>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.headerContainer}>
              <h1 className={styles.statusTitle}>Completed Nearby Comps Reports</h1>
            </div>
            <button className={styles.headerButton} onClick={handleNewGprReportClick}>
              Build New Report
            </button>
          </div>

          {isLoading ? (
            <div style={{ margin: '150px 0' }} className="spiner-container">
              <img src={spiner} alt="loading.." />
            </div>
          ) : (
            <CompletedReportsTableNearby
              rows={rows}
              columns={null}
              handleDeleteReport={handleDeleteReport}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default CompletedReportsNearby;
