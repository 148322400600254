import React, { useEffect } from 'react';
import Modal from 'react-modal';

import { ReactComponent as CloseSvg } from '../../../assets/images/gpr/closeModal.svg';

import styles from './LeaveConfirmationModal.module.scss';

export const customStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    boxShadow: '0px 3px 30px rgba(0, 0, 0, 0.08)',
    borderRadius: '15px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  }
};

const LeaveConfirmationModal = ({ isOpen, onRequestClose, onConfirm }) => {
  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      shouldCloseOnOverlayClick={false}>
      <CloseSvg className={styles.closeModalIcon} onClick={onRequestClose} />
      <h1 className={styles.processModalTitle}>Confirm leaving</h1>

      <span className={styles.processModalDescriptionText}>
        If you leave this page now, all your pre-filled <br />
        data will be discarded.
        <br />
        Confirm leaving?
      </span>
      <div className={styles.modalButtonContainer}>
        <button onClick={onRequestClose} className={styles.closeProcessModalButton}>
          Cancel
        </button>
        <button onClick={onConfirm} className={styles.confirmProcessModalButton}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default LeaveConfirmationModal;
