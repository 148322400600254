import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNearbyInputField } from '../../../store/actions';

import { BEDS_OPTIONS, BATHS_OPTIONS, BUILDING_TYPE } from '../../../constants/constants';
import CustomSelect from './components/CustomSelect.component';
import CustomAutocomplete from './components/CustomAutocomplete.component';

import styles from './NearbyCompleteReportInput.module.scss';
import { IRootState } from '../../../constants/types';

import location from '../../../assets/images/gpr/location.svg';

const NearbyCompleteReportInput = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state: IRootState) => state.nearbyCompsReducer);

  const onUpdateCount = (value, type) => {
    dispatch(updateNearbyInputField({ value, type }));
  };

  return (
    <>
      <div className={styles.contentTable}>
        <div className={styles.tableItems}>
          <div className={styles.item}>
            <div className={styles.itemAddress}>
              <div className={styles.itemAddressLabel}>Property Address</div>
              <CustomAutocomplete data={data} />
              <img className={styles.itemAddressImage} src={location} alt="location" />
            </div>

            <div className={styles.itemBedrooms}>
              <div className={styles.itemBedroomsLabel}>Bedrooms</div>
              <CustomSelect data={data} type={'bedrooms'} option={BEDS_OPTIONS} />
            </div>
            <div className={styles.itemBathrooms}>
              <div className={styles.itemBathroomsLabel}>Bathrooms</div>
              <CustomSelect data={data} type={'bathrooms'} option={BATHS_OPTIONS} />
            </div>
            <div className={styles.itemCurrentRent}>
              <div className={styles.itemCurrentRentLabel}>Size (sqft)</div>
              <div className={styles.itemCurrentRentInputContainer}>
                <input
                  className={styles.itemCurrentRentInput}
                  type="number"
                  value={data.size}
                  onChange={(e) => onUpdateCount(e.target.value, 'size')}
                />
                <div className={styles.itemCurrentRentPlaceholderSqft}>ft&#178;</div>
              </div>
            </div>
            <div className={styles.itemCurrentRent}>
              <div className={styles.itemCurrentRentLabel}>Current Rent</div>
              <div className={styles.itemCurrentRentInputContainer}>
                <input
                  className={styles.itemCurrentRentInput}
                  type="number"
                  value={data.currentRent}
                  onChange={(e) => onUpdateCount(e.target.value, 'currentRent')}
                />
                <div className={styles.itemCurrentRentPlaceholder}>$</div>
              </div>
            </div>
            <div className={styles.itemBathrooms}>
              <div className={styles.itemBathroomsLabel}>Building Type</div>
              <CustomSelect data={data} type={'type'} option={BUILDING_TYPE} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NearbyCompleteReportInput;
