import React, { useState } from 'react';
import classNames from 'classnames';

import styles from './FAQComponent.module.scss';

function FAQComponent({ data }) {
  const [expandedItems, setExpandedItems] = useState<number[]>([]);

  const toggleFAQ = (index: number) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  return (
    <div className={styles.faq}>
      <h2 className={styles.faqHeader}>Frequently Asked Questions</h2>
      <div className={styles.faqDescription}>
        If you have additional questions, please contact{' '}
        <a href="https://www.rentometer.com/feedback/new">support@rentometer.com</a>.
      </div>
      <div className={styles.faqContainer}>
        {data.map((faq, index) => (
          <div key={index} className={styles.faqItem}>
            <div
              className={classNames(
                styles.faqQuestion,
                expandedItems.includes(index) ? styles.faqExpanded : ''
              )}
              onClick={() => toggleFAQ(index)}>
              {faq.question}
            </div>
            <div
              className={classNames(
                styles.faqAnswerContainer,
                expandedItems.includes(index) ? styles.expanded : ''
              )}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQComponent;
