import Modal from 'react-modal';

export const customModalStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '15px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 20px 30px 30px'
  }
};

export const customSelectStyles = {
  control: (base) => ({
    border: '1px solid #B3BCC9',
    borderRadius: '4px',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '25px',
    color: '#717780',
    height: '46px',
    '@media screen and (max-width: 840px)': {
      width: '150px',
      fontSize: '14px'
      // height: '44px'
    },
    '@media screen and (max-width: 420px)': {
      fontSize: '10px',
      width: '100px',
      height: '37px'
    }
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#C24040',
      width: 'max-content'
    };
  },
  menuList: (base) => ({
    ...base,
    margin: 0,
    background: '#FFFFFF',
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    marginBottom: '20px',
    position: 'absolute',
    left: '-100px',
    top: '-100px',
    height: '113px',
    maxHeight: '200px',
    overflowY: 'scroll',
    padding: '15px 15px 9px 15px'
  }),
  option: () => ({
    margin: 0,
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '25px',
    color: '#717780',
    cursor: 'pointer',
    marginBottom: '6px',
    paddingLeft: '2px',
    '&:hover': {
      backgroundColor: '#9AA9BB20'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    margin: 0,
    padding: '0 15px 0 0'
  }),
  singleValue: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
    color: '#717780'
  }),
  valueContainer: (base) => ({
    ...base,
    margin: 0,
    padding: '10px 14px 9px',
    '@media screen and (max-width: 840px)': {
      padding: '10px 14px 9px'
    },
    '@media screen and (max-width: 420px)': {
      padding: '10px 10px 9px'
    }
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0
  })
};

export const customSelectAdditional = {
  control: (base) => ({
    border: '1px solid #B3BCC9',
    borderRadius: '4px',
    outline: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#ffffff',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '25px',
    color: '#717780',
    height: '46px',
    padding: '8px 10px',
    '@media screen and (max-width: 840px)': {
      width: '150px',
      fontSize: '14px'
      // height: '44px'
    },
    '@media screen and (max-width: 420px)': {
      fontSize: '10px',
      width: '100px',
      height: '37px'
    }
  }),
  menuList: (base) => ({
    ...base,
    marginTop: '5px',
    background: '#FFFFFF',
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    height: '113px',
    overflowY: 'auto',
    padding: '15px 15px 9px 15px'
  }),
  option: () => ({
    margin: 0,
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '25px',
    color: '#717780',
    cursor: 'pointer',
    marginBottom: '6px',
    paddingLeft: '2px',
    '&:hover': {
      backgroundColor: '#9AA9BB20'
    }
  }),
  multiValue: (base) => ({
    ...base,
    borderRadius: '3px',
    backgroundColor: '#D2DBE8'
  }),
  multiValueLabel: (base) => ({
    ...base,
    fontFamily: 'Roboto',
    fontWeight: 400,
    padding: '3px',
    color: '#2D3035',
    fontSize: '14px',
    lineHeight: '19px'
  }),
  singleValue: (base) => ({
    ...base,
    margin: 0,
    padding: 0
  }),
  valueContainer: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
    maxWidth: '450px',
    flexWrap: 'nowrap'
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '14px'
  })
};

export const customStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    boxShadow: '0px 3px 30px rgba(0, 0, 0, 0.08)',
    borderRadius: '15px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  }
};
