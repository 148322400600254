import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

import { deleteReport } from '../../../store/actions';

import { IRootState } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';

import { ReactComponent as CloseSvg } from '../../../assets/images/gpr/closeModal.svg';
import { ReactComponent as AdditionalSvg } from '../../../assets/images/gpr/additional.svg';

import styles from './CompletedReportDropdown.module.scss';

export const customStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '15px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  }
};

const CompletedReportDropdown = ({ recordId, handleEditTitle, recordTitle }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [modalIsOpen, setIsOpen] = useState(false);

  const [choosenID, setChoosenID] = useState('');
  const state = useSelector((state: IRootState) => state.batchAnalysisReducer);
  const { apiKey, keyIsLoaded } = useSelector((state: IRootState) => state.authReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  const handleViewReports = (id?: string) => navigate(`/batch-processor/${id}/reports`);

  const handleDownloadCSV = (id: string) => {
    const { addresses = [], title = '' } = state.history[id] || {};
    const dataToParse = addresses.map(({ report, additionalHeader, inputAddress }) => {
      return {
        address: report.address,
        latitude: report.latitude,
        longitude: report.longitude,
        bedrooms: report.bedrooms,
        baths: report.baths,
        building_type: report.building_type,
        look_back_days: report.look_back_days,
        mean: report.mean,
        median: report.median,
        min: report.min,
        max: report.max,
        percentile_25: report.percentile_25,
        percentile_75: report.percentile_75,
        std_dev: report.std_dev,
        samples: report.samples,
        radius_miles: report.radius_miles,
        quickview_url: report.quickview_url,
        updatedAt: report.updatedAt,
        // credits_remaining: report.credits_remaining,
        token: report.token,
        ...(inputAddress ? { input_address: inputAddress } : {}),
        ...(additionalHeader ?? {})
        // links: a.links
      };
    });
    const csv = Papa.unparse(dataToParse);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${title}.csv`);
  };

  const openModal = (id) => {
    setIsOpen(true);
    setChoosenID(id);
  };
  const closeModal = () => setIsOpen(false);

  const handleConfirmModal = async () => {
    if (keyIsLoaded) {
      const data = await fetch(`${ENVS.PROD}/user/delete/${apiKey}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: choosenID })
      });
      const json = await data.json();
      dispatch(deleteReport({ id: choosenID }));
      closeModal();
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const onSelectOption = (option) => {
    if (option === 'view') {
      handleViewReports(recordId);
    } else if (option === 'delete') {
      openModal(recordId);
    } else if (option === 'download') {
      handleDownloadCSV(recordId);
    } else if (option === 'rename') {
      handleEditTitle(recordId, recordTitle);
    }
    closeDropdown();
  };

  const dropdownOptions = [
    { label: 'View', value: 'view' },
    { label: 'Delete', value: 'delete' },
    { label: 'Download', value: 'download' },
    { label: 'Rename', value: 'rename' }
  ];

  useEffect(() => {
    if (isDropdownOpen) {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          closeDropdown();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isDropdownOpen]);

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownToggle} onClick={toggleDropdown}>
        <AdditionalSvg width={5} height={20} />
      </div>
      {isDropdownOpen && (
        <div className={styles.dropdownOptions} ref={dropdownRef}>
          {dropdownOptions.map((option) => (
            <div
              key={option.value}
              className={styles.dropdownOption}
              onClick={() => onSelectOption(option.value)}>
              {option.label}
            </div>
          ))}
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}>
        <CloseSvg className={styles.closeModalIcon} onClick={() => closeModal()} />
        <h1 className={styles.modalTitle}>Delete batch?</h1>
        <span className={styles.modalDescription}>
          The Batch will be permanently deleted. Please download CSV if you will need a copy in the
          future.
        </span>
        <div className={styles.modalButtonContainer}>
          <div className={styles.modalButtonContainerSmall}>
            <button
              className={styles.modalDownloadButton}
              onClick={() => handleDownloadCSV(recordId)}>
              Download CSV
            </button>
            <button onClick={() => handleConfirmModal()} className={styles.modalConfirmButton}>
              Confirm
            </button>
          </div>
          <button onClick={() => closeModal()} className={styles.modalCancelButton}>
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CompletedReportDropdown;
