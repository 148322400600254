import React from 'react';
import styles from './MessageComponents.module.scss';

interface LackOfAPIKeyMessageProps {
  authorized: boolean;
}

const LackOfAPIKeyMessage: React.FC<LackOfAPIKeyMessageProps> = ({ authorized }) => {
  if (authorized) {
    return null;
  }

  return (
    <div className={styles.creditsAvailabilityContainer}>
      <span className={styles.creditsAvailabilityText}>
        To run the report, please sign in.
        <a
          href={'https://www.rentometer.com/rentometer-api/settings?from-batch-tool=1'}
          className={styles.creditsAvailabilityLink}>
          Sign in.
        </a>
      </span>
    </div>
  );
};

export default LackOfAPIKeyMessage;
