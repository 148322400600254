import React from 'react';
import { ReactComponent as HelpCenterSvg } from '../../assets/images/helpCenter.svg';

const HelpCenter = () => {
  return (
    <a
      style={{
        position: 'fixed',
        bottom: '0px',
        right: '0px',
        margin: '0 30px 24px 0'
      }}
      href="https://rentometer.zendesk.com/hc/en-us/sections/13106589370260-Tools"
      rel="noreferrer"
      target="_blank">
      <HelpCenterSvg />
    </a>
  );
};

export default HelpCenter;
