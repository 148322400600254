import React, { useState } from 'react';
import { Table } from 'antd';
import styles from './ResultTable.module.scss';
import { ReactComponent as CopyLinkSvg } from '../../../assets/images/CopyLink.svg';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

type Props = {
  rows: object[];
  columns?: object[];
  reportId?: string;
};
const ResultTable = ({ rows, columns, reportId }: Props) => {
  const [copied, setCopied] = useState(false);
  const [copiedUrl, setCopiedUrl] = useState('');

  const handleCopyUrl = (url) => {
    setTimeout(() => {
      setCopiedUrl(url);
      setCopied(true);
    }, 300);
    setTimeout(() => {
      setCopiedUrl('');
      setCopied(false);
    }, 1000);
  };

  const customColumns = [
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address',
      align: 'left',
      render: (text, record) => {
        return (
          <div className={styles.addressContainer}>
            <span className={styles.addressText}>{text}</span>
            {record?.report?.quickview_url !== '-' && record?.report?.quickview_url !== '' ? (
              <div className={styles.copySvgContainer}>
                <CopyToClipboard
                  text={record?.report?.quickview_url}
                  onCopy={(url) => handleCopyUrl(url)}>
                  <CopyLinkSvg className={styles.copySvg} />
                </CopyToClipboard>
                {copied && record?.report?.quickview_url === copiedUrl && (
                  <span className={styles.copied}>Copied!</span>
                )}
              </div>
            ) : (
              <div className={styles.infoSvgContainer}>
                <InfoCircleOutlined className={styles.infoSvg} />
                <div className={styles.hide}>
                  There are not enough properties or you may have a “#” sign character in your
                  input.
                </div>
              </div>
            )}
          </div>
        );
      }
    },
    {
      title: 'Mean',
      key: 'mean',
      dataIndex: 'report',
      align: 'left',
      width: 120,
      render: (item) => item.mean
    },
    {
      title: 'Median',
      key: 'median',
      dataIndex: 'report',
      align: 'left',
      width: 120,
      render: (item) => item.median
    },
    {
      title: 'Bedrooms',
      key: 'bedrooms',
      dataIndex: 'report',
      align: 'left',
      width: 120,
      render: (item) => item.bedrooms
    },
    {
      title: 'Baths',
      key: 'baths',
      dataIndex: 'report',
      align: 'left',
      width: 120,
      render: (item) => item.baths
    },
    {
      title: 'Building Type',
      key: 'building_type',
      dataIndex: 'report',
      align: 'left',
      width: 150,
      render: (item) => item.building_type
    }
  ];
  return (
    <Table
      locale={{
        emptyText: 'No Data'
      }}
      columns={columns || customColumns}
      dataSource={[...rows]}
      pagination={false}
      className={styles.resultTable}
      scroll={{ x: 'max-content' }}
    />
  );
};

export default ResultTable;
