import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone, FileError, FileRejection, DropEvent } from 'react-dropzone';
import classNames from 'classnames';
import styles from './Dropzone.module.scss';
import { CloudUploadOutlined, InfoCircleOutlined } from '@ant-design/icons';

type UploadableFile = {
  file: File;
  errors: FileError[];
};

type Props = {
  accept?: string | string[];
  className?: string;
  minSize?: number;
  maxSize?: number;
  maxFiles?: number;
  preventDropOnDocument?: boolean;
  noClick?: boolean;
  noKeyboard?: boolean;
  noDrag?: boolean;
  noDragEventsBubbling?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  onDragEnter?: () => void;
  onDragLeave?: () => void;
  onDragOver?: () => void;
  onDropAccepted?: <T extends File>(files: T[], event: DropEvent) => void;
  onDropRejected?: (fileRejections: FileRejection[], event: DropEvent) => void;
  getFilesFromEvent?: (event: DropEvent) => Promise<Array<File | DataTransferItem>>;
  onFileDialogCancel?: () => void;
  onFileDialogOpen?: () => void;
  validator?: <T extends File>(file: T) => FileError | FileError[] | null;
  useFsAccessApi?: boolean;
};

export function DropZone({
  accept,
  onDrop,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onDropAccepted,
  onDropRejected,
  onFileDialogCancel,
  onFileDialogOpen,
  validator,
  maxFiles,
  maxSize,
  minSize,
  disabled,
  noDragEventsBubbling,
  multiple,
  noClick,
  noDrag,
  noKeyboard,
  preventDropOnDocument,
  useFsAccessApi,
  className
}: Props) {
  const [files, setFiles] = useState<UploadableFile[]>([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const customOnDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    const mappedFiles = acceptedFiles.map((file) => ({ file, errors: [] }));
    setFiles((prev) => [...prev, ...mappedFiles, ...rejectedFiles]);
  }, []);

  const { getRootProps, getInputProps, open, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'text/csv': ['.csv']
    },
    onDrop: onDrop || customOnDrop,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    onFileDialogOpen,
    maxFiles,
    maxSize,
    minSize,
    disabled,
    noDragEventsBubbling,
    multiple: multiple || true,
    noClick: noClick || true,
    noDrag,
    noKeyboard,
    preventDropOnDocument,
    useFsAccessApi
  });

  const style = useMemo(
    () => ({
      ...(isFocused ? { borderColor: '#1B74B3', background: '#9AA9BB10' } : {}),
      ...(isDragAccept ? { borderColor: '#007600', background: '#9AA9BB10' } : {}),
      ...(isDragReject ? { borderColor: '#EF3921', background: '#9AA9BB10' } : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div
      {...getRootProps({ style })}
      className={classNames(styles.container, disabled && styles.disabled, className)}>
      <CloudUploadOutlined className={styles.cloud} />
      {showErrorMessage && <span className={styles.errorMessage}>Inappropriate file type</span>}
      <h1 className={styles.title}>
        Drag, drop or
        <input {...getInputProps()} />
        <span className={styles.browse} onClick={open}>
          {' '}
          upload{' '}
        </span>
        your CSV file(s)
      </h1>
    </div>
  );
}
