import React from 'react';
import './CustomDtagNDropeList.scss';

import DragIcon from '../../../assets/images/dragIcon.svg';
import { ReactComponent as CloseSvg } from '../../../assets/images/gpr/closeModal.svg';

function ListSort({ closeFilter, setFilters }) {
  const [filterList, setFilterList] = React.useState([
    'Size (sqft)',
    'Price (sqft)',
    'Price',
    'List Date',
    'Bedrooms',
    'Bathrooms',
    'Distance',
    'Property Type'
  ]);

  //save reference for dragItem and dragOverItem
  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);

  //const handle drag sorting
  const handleSort = () => {
    //duplicate items
    let _filterList = [...filterList];

    //remove and save the dragged item content
    const draggedItemContent = _filterList.splice(dragItem.current, 1)[0];

    //switch the position
    _filterList.splice(dragOverItem.current, 0, draggedItemContent);

    //reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;

    //update the actual array
    setFilterList(_filterList);
    setFilters(_filterList);
  };

  return (
    <div className="list-container">
      <div className="listTitle">
        <h4>Filters List</h4>
        <CloseSvg onClick={closeFilter} />
      </div>
      <div className="list-container__left">
        {filterList.map((item, index) => (
          <div
            key={index}
            className="list-item"
            draggable
            onDragStart={(e) => (dragItem.current = index)}
            onDragEnter={(e) => (dragOverItem.current = index)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}>
            <div className="list-itemText">{item}</div>
            <img src={DragIcon} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ListSort;
