import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { updateNearbyInputField } from '../../../../store/actions';

const CustomAutocomplete = ({ data }) => {
  const [address, setAdress] = useState('');
  const dispatch = useDispatch();

  const onUpdateField = (e) => {
    setAdress(e);
    dispatch(updateNearbyInputField({ type: 'address', value: e }));
  };

  return (
    <GooglePlacesAutocomplete
      apiKey="AIzaSyAwopNugn6tNb-1hXDY_ORinP5wV16vTUo"
      apiOptions={{ language: 'en', region: 'us' }}
      autocompletionRequest={{
        types: ['address'],
        componentRestrictions: {
          country: 'us'
        }
      }}
      selectProps={{
        isClearable: true,
        placeholder: '',
        value: data.address,
        onChange: (e) => onUpdateField(e),
        components: {
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          IndicatorsContainer: () => null
        },
        styles: {
          noOptionsMessage: () => ({
            margin: 0
          }),
          loadingMessage: () => ({
            margin: 0
          }),
          control: (base) => ({
            ...base,
            heightminWidth: '50px',
            border: '1px solid #b3bcc9',
            borderRadius: '4px',
            textAlign: 'left',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '16px',
            color: '#303134'
          }),
          menu: (base) => ({
            ...base,
            background: '#FFFFFF',
            boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.15)',
            border: 'none',
            borderRadius: '5px',
            maxHeight: '180px',
            overflowY: 'scroll',
            padding: '15px'
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#F2F7FB' : 'white',
            borderRadius: '5px',
            textAlign: 'left',
            margin: 0,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '25px',
            color: '#303134',
            paddingLeft: '3px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#F2F7FB'
            }
          }),
          valueContainer: (base) => ({
            ...base,
            margin: '0 30px 0 auto',
            '@media screen and (max-width: 1020px)': {
              padding: '0 30px 0 auto'
            },
            '@media screen and (max-width: 420px)': {
              padding: '0 30px 0 auto'
            }
          })
        }
      }}
    />
  );
};

export default CustomAutocomplete;
