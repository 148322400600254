import React from 'react';
import { ReactComponent as NotFoundSvg } from '../../assets/images/Error.svg';
import styles from './Error.module.scss';

const NotFound = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Error 404</h1>
      <span className={styles.description}>Page Not Found</span>
      <NotFoundSvg />
    </div>
  );
};

export default NotFound;
